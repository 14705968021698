import React, { useEffect, useState } from 'react';
import Layout from '../layouts/Layout';
import { Button, Spinner, Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
const Promotion = () => {
    const endpointURL =process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_MODE : process.env.REACT_APP_PRO_MODE;
    const [isLoading,setIsLoading] = useState(false);
    const [guides,setGuide] = useState([]);
    const navigate = useNavigate();
    const [submitted,setSubmitted] = useState(false);
    const handleFetch = async () => {
        try {
          const response = await fetch(endpointURL+'/api/v1/guides',{
            headers: { 
                'Content-Type': 'application/json'
              }
          });
          const result = await response.json(); 
          if(result.status === true)
          {
            setGuide(result.data);
            setIsLoading(false);
          }
        } catch (error) {
          console.error('fetch data error:', error);
        }
    };
    const handleView = (list) => {
        navigate("/promotion-detail/"+list.id);
    }
    useEffect(()=>{
        if(!submitted){
            setIsLoading(true);
            setSubmitted(true);
            handleFetch();
        }
        console.count('render count: ');
    },[]);
    return ( 
        <Layout>
            <Helmet>
                <title>Promotion</title>
            </Helmet>
            { isLoading ? (
                     <div className="row text-center">
                        <div className="col-12">
                            <div className="p-4" style={{border:"1px solid #0d64ce",marginTop:"20px",borderRadius:"5px"}}>
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>  လုပ်ဆောင်နေသည်...ခဏစောင့်ပါ
                            </div>
                        </div>
                     </div>
                ):
                guides.length > 0 ? 
                <Stack gap={2} className="mt-2 mx-auto" >
                    {guides.map((list,index)=>(
                            <Button key={index} variant="outline-secondary   p-1" onClick={()=>handleView(list)}>  
                                <h5>{list.title}</h5>
                                <span style={{color:"rgb(204,204,204)"}}>{list.short_note}</span>
                            </Button>
                        ))
                    }
                </Stack>
                :
                (
                    <div className="row text-center">
                        <div className="col-12">
                            <div className="p-4" style={{border:"1px solid #0d64ce",marginTop:"20px",borderRadius:"5px"}}>
                                Promotion မရှိပါ
                            </div>
                        </div>
                     </div>
                )
            }
        </Layout>
     );
}
 
export default Promotion;