import { Helmet } from 'react-helmet';
import Layout from '../layouts/Layout';
const AppDownload = () => {
    const handleDownload = () =>{

    }
    return ( 
        <Layout>
            <Helmet>
                <title>App Download</title>
            </Helmet>
            <div className="row">
                <div className="col-12 mb-3" style={{textAlign:"center"}}>
                    <img src="logo.png" style={{width:"130px"}} alt="Logo"></img>
                </div>
                <div className="col-12" style={{textAlign:"center"}}>
                    <h4>အက်ပ်များကို ဒေါင်းလုဒ်လုပ်ပါ</h4>
                </div>
                <div className="col-12 mt-4" style={{textAlign:"center"}}>
                    <a href='/promotion-detail/1' className='btn btn-primary' style={{marginRight:"20px"}}><i className='lni lni-apple'></i> iOS</a>
                    <a href='https://admin.shwekyee.com/logo/shwekyee.apk' className='btn btn-primary'  download><i className='lni lni-android'></i> Android</a>
                </div>
            </div>
        </Layout>
     );
}
 
export default AppDownload;