import { useLocation, useNavigate } from "react-router-dom";
import Layout2 from "../layouts/Layout2";
import { useEffect, useState } from "react";
import { MuiOtpInput } from 'mui-one-time-password-input';
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
const OtpVerifyForget = () => {
    const endpointURL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_MODE : process.env.REACT_APP_PRO_MODE;
    const location = useLocation();
    const [otp, setOtp] = useState('');
    const username = location.state?.username;
    const name = location.state?.name;
    const [code,setCode] = useState(location.state?.request_id);
    const [isLoading,setIsLoading] = useState(false);
    const [submitted,setSubmitted] = useState(false);
    const navigate = useNavigate();
    const handleChange = (newValue) => {
      setOtp(newValue)
    }
    const resetOtp = async (e) => {
        e.preventDefault();
        setOtp("");
        const registerArray = {
            "phone": username
        };
        if(!submitted){
            setSubmitted(true);
            try{
                const response = await fetch(endpointURL+"/api/v1/otp-request-forget?apiKey=shwekyee",{
                    method:"POST",
                    headers:{
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(registerArray),
                });
                if(!response.ok)
                {
                    throw new Error('Request failed');
                }
                const resp = await response.json();
                console.log(resp);
                if(resp.status === true)
                {
                    toast.success(resp.msg,{position: toast.POSITION.TOP_CENTER,autoClose:false});
                    setCode(resp.data.request_id);   
                    //console.log(code);    
                }
            }catch(error){
                console.error('otp reset failed: ',error.message);
            }
            setSubmitted(false);
        }
    }
    const handleVerify = async (e) =>{
        e.preventDefault();
        const verifyArray = {
            "number": username,
            "request_id":code,
            "code":otp,
            "resetProcess":"getToken"
        };
        setIsLoading(true);
        //console.log(verifyArray);
        if(!submitted){
            setSubmitted(true);
            try{
                const response = await fetch(endpointURL+"/api/v1/otp-verify?apiKey=shwekyee",{
                    method:"POST",
                    headers:{
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(verifyArray),
                });
                if(!response.ok)
                {
                    throw new Error('Request failed');
                }
                const resp = await response.json();
                if(resp.status === true)
                {
                    //console.log(resp);
                    localStorage.setItem('authenticated',true);
                    localStorage.setItem('token', resp.token);
                    navigate('/reset-password', {state:{username:username,verify:true}});
                }else
                {
                    toast.error(resp.msg,{position: toast.POSITION.TOP_CENTER,autoClose:false});
                }
            }catch(error){
                toast.error(error.message,{position: toast.POSITION.TOP_CENTER,autoClose:false});
            }
            setSubmitted(false);
            setIsLoading(false);
        }
    }
     useEffect(() => {
        //console.log(location.state.request_id);
        if(name === undefined && username === undefined){
            navigate("/register");
        }
    });
    return ( 
        <Layout2 pageType={"Password မေ့နေသည်"}>
            <Helmet>
                <title>OTP Verify</title>
            </Helmet>
            <p style={{color:"rgb(204,204,204)",textAlign:"center"}}>မိမိဖုန်းသို့ပေးပို့ထားသည့်ကုဒ်(၆)လုံးအားထည့်သွင်းပါ</p>    
            <form onSubmit={handleVerify}>
                <MuiOtpInput length={6} className="otp"  value={otp} onChange={handleChange} TextFieldsProps={{ size: 'small' }} />
                <div className="mt-3">
                    <a href="{#}" onClick={resetOtp} style={{color:"rgb(204,204,204)"}}>ကုဒ်ပြန်ပို့ပါ</a>
                </div>
                <div className="mt-2">
                    <center>571098 ကိုရိုက်ထည့်ပါ</center>
                </div>
                <div className="form-group mt-4">
                    <div className="col-12" style={{textAlign:"center"}}>
                        <button type="submit" className="btn btn-md bg-warning"><i className="bx bx-right-arrow-alt text-white"></i>{isLoading?"Loading":"ရှေ့ဆက်မည်"}</button>
                    </div>
                </div>
            </form>
        </Layout2>
     );
}
 
export default OtpVerifyForget;