import React, { useEffect, useState } from "react";
import Layout2 from "../layouts/Layout2";
import FeatureList from "../components/FeatureList";
import '../App.css';
import { Link } from "react-router-dom";
import KeenSlider from "../components/KeenSlider";
import { Helmet } from "react-helmet";
const TwoHome = () => {
    const [set_1200,set_1200_value] = useState("--");
    const [val_1200,val_1200_value] = useState("--");
    const [result_1200,result_1200_value] = useState("--");

    const [set_430,set_430_value]   = useState("--");
    const [val_430,val_430_value]   = useState("--");
    const [result_430,result_430_value] = useState("--");

    const [animatelive1200,setAnimateLive1200] = useState(false);
    const [animatelive1630,setAnimateLive1630] = useState(false);
    const [date,setDate]  = useState("");
    const [livepage,setLivepage] = useState(false);
    const t1201 = async () => {
        if(livepage === true)
        {
            try {
                const response = await fetch('https://luke.2dboss.com/api/luke/twod-result-live');
                const result = await response.json();
                setDate(result.data.date);
                if(result.data.is_close_day == 1)
                {
                    /**
                     * 12:00
                     */
                    set_1200_value(result.data.set_1200);
                    val_1200_value(result.data.val_1200);
                    result_1200_value(result.data.result_1200);
                    setAnimateLive1200(false);
                    /**
                     * 16:30
                     */
                    set_430_value(result.data.set_430);
                    val_430_value(result.data.val_430);
                    result_430_value(result.data.result_430);
                    setAnimateLive1630(false);
                }else if(result.data.current_time >= "10:00:00" && result.data.current_time <= "12:03:00")
                {
                    set_1200_value(result.data.live_set);
                    val_1200_value(result.data.live_val);
                    result_1200_value(result.data.live);
                    setAnimateLive1200(true);
                    setAnimateLive1630(false);
                    //console.log(result.data.set_1200);
                }else if(result.data.current_time > "12:03:00" && result.data.current_time <= "16:34:00") 
                {
                    /**
                     * 12:00
                     */
                    set_1200_value(result.data.set_1200);
                    val_1200_value(result.data.val_1200);
                    result_1200_value(result.data.result_1200);
                    setAnimateLive1200(false);
                    /**
                     * 16:30
                     */
                    set_430_value(result.data.live_set);
                    val_430_value(result.data.live_val);
                    result_430_value(result.data.live);
                    setAnimateLive1630(true);
                }else
                {
                    /**
                     * 12:00
                     */
                    set_1200_value(result.data.set_1200);
                    val_1200_value(result.data.val_1200);
                    result_1200_value(result.data.result_1200);
                    setAnimateLive1200(false);
                    /**
                     * 16:30
                     */
                    set_430_value(result.data.set_430);
                    val_430_value(result.data.val_430);
                    result_430_value(result.data.result_430);
                setAnimateLive1630(false);
                }
            } catch (error) {
                console.error('fetch data error:', error);
            }
        }
    }
    
     setTimeout(t1201, 8000);
    ////clearTimeout(timerId);
    // setTimeout(() => {
    //     t1201();
    // }, 8000);
   // console.log(data);
   useEffect(() =>{
    setLivepage(true);
    t1201();
   },[]);
    return ( 
        <Layout2 pageType={"နှစ်လုံး"}>
            <Helmet>
                <title>2D Home Page</title>
            </Helmet>
            <FeatureList></FeatureList>
            <br></br>
            <KeenSlider id={2}></KeenSlider> 
            <center className="mx-auto mt-2" style={{color:"rgb(204,204,204)"}}>{date}</center>   
            <div style={{border:"1px dashed gold",marginTop:"10px",borderRadius:"10px"}}>
                <div className="row" style={{padding:"0",margin:"0"}}>
                    <div className="col-12 text-center p-2">
                        <h2 className={animatelive1200 ? "animate-charcter":""}>{result_1200}</h2>
                    </div>
                    <div className="col-12" style={{background:"#0f0f0f"}}>
                        <table style={{width:"100%"}}>
                            <tbody>
                                <tr>
                                    <td style={{textAlign:"center"}}>
                                        <span>Time</span><br></br>
                                        12:01
                                    </td>
                                    <td style={{textAlign:"center"}}>
                                        <span>Set</span><br></br>
                                        {set_1200}
                                    </td>
                                    <td style={{textAlign:"center"}}>
                                        <span>Value</span><br></br>
                                        {val_1200}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div style={{border:"1px dashed gold",marginTop:"20px",borderRadius:"10px"}}>
                <div className="row" style={{padding:"0",margin:"0"}}>
                    <div className="col-12 text-center p-2">
                        <h2 className={animatelive1630 ? "animate-charcter":""}>{result_430}</h2>
                    </div>
                    <div className="col-12" style={{background:"#0f0f0f"}}>
                        <table style={{width:"100%"}}>
                            <tbody>
                                <tr>
                                    <td style={{textAlign:"center"}}>
                                        <span>Time</span><br></br>
                                        16:30
                                    </td>
                                    <td style={{textAlign:"center"}}>
                                        <span>Set</span><br></br>
                                        {set_430}
                                    </td>
                                    <td style={{textAlign:"center"}}>
                                        <span>Value</span><br></br>
                                        {val_430}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-12 mt-4 text-center">
                    <Link to="/two-home/create">
                        <button className="btn btn-outline-primary"><i className="bx bx-dollar text-primary"></i> ထိုးမည်</button>
                    </Link>
                </div>
            </div>
        </Layout2>
     );
}
 
export default TwoHome;