import { useEffect, useState } from "react";
import Layout2 from "../layouts/Layout2";
import { Table,Spinner } from "react-bootstrap";
import { Helmet } from "react-helmet";
const CloseDate = () => {
    const [isLoading,setIsLoading] = useState(false);
    const [data,setData] = useState([]);
    const [error,setError] = useState("");
    const [submitted,setSubmitted] = useState(false);
    const endpointURL =process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_MODE : process.env.REACT_APP_PRO_MODE;
    const fetchCloseDate = async () => {
        
            try {
                const response = await fetch(endpointURL+'/api/v1/close-date',{
                headers: { 
                    'Content-Type': 'application/json'
                    }
                });
                if(!response.ok)
                {
                    //console.log(response);
                    setError(response.statusText);
                }
                const result = await response.json(); 
                if(result.status === true)
                {
                    setData(result.data);
                    setIsLoading(false);
                }
            } catch (error) {
                console.error('fetch data error:', error);
            }
        
    }
    useEffect(()=>{
        setIsLoading(true);
        if(!submitted){
            setSubmitted(true);
            fetchCloseDate();
        }
    },[]);
    return ( 
        <Layout2 pageType={"ပိတ်ရက်များ"}>
            <Helmet>
                <title>2D ပိတ်ရက်များ</title>
            </Helmet>
            <p style={{color:"rgb(204,204,204)",textAlign:"center"}}>ပိတ်ရက်များဖြစ်ပါသည်</p>    
            <Table striped bordered hover  style={{width:"100%",color:"rgb(204,204,204)"}}  variant="dark">
                <thead>
                    <tr>
                      <th style={{fontSize:"13px",padding:"10px",color:"rgb(204,204,204)"}}>ရက်စွဲ</th>
                      <th style={{fontSize:"13px",color:"rgb(204,204,204)"}}>အကြောင်းအရာ</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!isLoading ?
                        data.length > 0 ?
                            data.map((list,key) =>(
                                <tr key={key}>
                                    <td style={{cursor:"pointer"}}>{list.date}</td>
                                    <td style={{cursor:"pointer"}}>{list.description}</td>
                                </tr>
                            )): <tr><td colSpan={2} style={{textAlign:'center',color:"rgb(204,204,204)",fontSize:"13px",padding:"8px"}}>ပိတ်ရက်မရှိသေးပါ...</td></tr>
                        :
                            <tr>
                                <td colSpan={2} style={{textAlign:'center'}}>
                                { error !=="" ? error:(
                                    <div>
                                        <Spinner animation="border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner> လုပ်ဆောင်နေသည်...ခဏစောင့်ပါ 
                                    </div>  
                                    )   
                                }
                                </td>
                            </tr>
                         
                    }
                  </tbody>
            </Table>
        </Layout2>
     );
}
 
export default CloseDate;