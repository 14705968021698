import React, { useEffect, useState } from 'react';
import Layout2 from '../layouts/Layout2';
import Form from 'react-bootstrap/Form'; 
import { toast } from "react-toastify";
import { Table,Spinner } from 'react-bootstrap';
import TwoBetDetail from '../components/TwoBetDetail';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
const TwoBet = () => {
    const [twobet,setTwobet] = useState([]);
    const [from,setFrom] = useState("");
    const [to,setTo]  = useState("");
    const [isLoading,setIsLoading] = useState(false);
    const [isLoadingDetail,setIsLoadingDetail] = useState(false);
    const [twobetdetailmodal,setTwoBetDetailModal] = useState(false);
    const [twobetdetail,setTwoBetDetail] = useState([]);
    const navigate = useNavigate();
    const endpointURL =process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_MODE : process.env.REACT_APP_PRO_MODE;
    const handleTwoBet = async (e) => {
          try{
            let initialArray = {from:from,to:to};  
            const response = await fetch(endpointURL+"/api/v1/two-bets",{
                method:"POST",
                headers: { 
                  'Content-Type': 'application/json',
                  'Authorization':  'Bearer '+localStorage.getItem('token'),
                },
                body: JSON.stringify(initialArray),
            });
            const result = await response.json();
            if(result.status === true)
            {
             setTwobet(result.data);
             setIsLoading(false);
            }else
            {
                toast.error(result.msg,{position: toast.POSITION.TOP_CENTER,autoClose:false});
            }
          }catch(error){
            toast.error("Error occurred!Try again",{position: toast.POSITION.TOP_CENTER,autoClose:false});
          }
      }
    const handleTwoBetSearch = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try{
          let initialArray = {from:from,to:to};
          const response = await fetch(endpointURL+"/api/v1/two-bets",{
              method:"POST",
              headers: { 
                'Content-Type': 'application/json',
                'Authorization':  'Bearer '+localStorage.getItem('token'),
              },
              body: JSON.stringify(initialArray),
          });
          const result = await response.json();
          if(result.status === true)
          {
           setTwobet(result.data);
           setIsLoading(false);
          }else
          {
            toast.error(result.msg,{position: toast.POSITION.TOP_CENTER,autoClose:false});
          }
        }catch(error){
            toast.error("Error occurred!Try again",{position: toast.POSITION.TOP_CENTER,autoClose:false});
        }
    }
    const handleTwoBetDetail = async (date,time) => {
        /**
         * show modal true
         */
        setTwoBetDetailModal(true);
        setIsLoadingDetail(true);
        try{
            let initialArray = {date:date,time:time};
            const response = await fetch(endpointURL+"/api/v1/two-bets-detail",{
                method:"POST",
                headers: { 
                  'Content-Type': 'application/json',
                  'Authorization':  'Bearer '+localStorage.getItem('token'),
                },
                body: JSON.stringify(initialArray),
            });
            const result = await response.json();
            if(result.status === true)
            {
              setTwoBetDetail(result.data);
              setIsLoadingDetail(false);
            }else
            {
                toast.error(result.msg,{position: toast.POSITION.TOP_CENTER,autoClose:false});
            }
          }catch(error){
            toast.error("Error occurred!Try again",{position: toast.POSITION.TOP_CENTER,autoClose:false});
          }
    }
    useEffect(() =>{
      if(localStorage.getItem('token') == null || localStorage.getItem('token')=="")
      {
        alert('Login ဝင်ရောက်ပါ...');
        navigate("/");
      }else
      {
        setIsLoading(true);
        handleTwoBet();
        console.count('render counts:');
      }
    },[]);
    return ( 
        <Layout2 pageType={"ထိုးမှတ်တမ်း"}>
            <Helmet>
                <title>2D ထိုးမှတ်တမ်း</title>
            </Helmet>
            <Form className='row' onSubmit={handleTwoBetSearch}> 
                <Form.Group className='col-6'> 
                    <Form.Label>From:</Form.Label> 
                    <Form.Control type="date" className='bg-dark' style={{color:"rgb(204,204,204)"}} onChange={(e) => setFrom(e.target.value)} /> 
                </Form.Group> 
                <Form.Group className='col-6'> 
                    <Form.Label>To:</Form.Label> 
                    <Form.Control type="date" className='bg-dark' style={{color:"rgb(204,204,204)"}} onChange={(e) => setTo(e.target.value)} /> 
                </Form.Group> 
                <Form.Group className='col-12 mt-2' style={{textAlign:'center'}}> 
                    <button className="btn btn-outline-primary"  type="submit"> 
                      <i className='lni lni-search text-primary'></i>  
                      {!isLoading?"ရှာမည်":"Loading..."}
                    </button> 
                </Form.Group> 
            </Form> 
            <br></br>
            <p style={{color:"rgb(204,204,204)"}}>လွန်ခဲ့သော (၁၀)ကြိမ်အား ဖော်ပြပေးထားပါသည်</p>    
            <Table striped bordered hover  style={{width:"100%",color:"rgb(204,204,204)"}}  variant="dark">
                <thead>
                    <tr>
                      <th style={{fontSize:"13px",padding:"10px"}}>ရက်စွဲ</th>
                      <th style={{fontSize:"13px"}}>အချိန်</th>
                      <th style={{fontSize:"13px"}}>ပမာဏ</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!isLoading ? 
                        twobet.length > 0 ?
                          twobet.map((bet) =>(
                              <tr onClick={(e)=> handleTwoBetDetail(bet.date,bet.time)}>
                                  <td style={{cursor:"pointer"}}>{bet.date}</td>
                                  <td style={{cursor:"pointer"}}>{bet.time}</td>
                                  <td style={{cursor:"pointer"}}>{bet.amount}</td>
                              </tr>
                          )):
                          <tr><td colSpan={3} style={{textAlign:'center',color:"rgb(204,204,204)",fontSize:"13px",padding:"8px"}}>ထိုးမှတ်တမ်းမရှိသေးပါ...</td></tr>
                        :(
                            <tr>
                                <td colSpan={3} style={{textAlign:'center'}}>
                                  <Spinner animation="border" role="status">
                                      <span className="visually-hidden">Loading...</span>
                                  </Spinner>  လုပ်ဆောင်နေသည်...ခဏစောင့်ပါ
                                </td>
                            </tr>
                        )  
                    }
                  </tbody>
            </Table>
            <TwoBetDetail betlists={twobetdetail} isLoadingDetail={isLoadingDetail} show={twobetdetailmodal} onHide={(e)=>setTwoBetDetailModal(false)}></TwoBetDetail>
        </Layout2>
     );
}
 
export default TwoBet;