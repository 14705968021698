import { Helmet } from "react-helmet";
import Layout2 from "../layouts/Layout2";
import { useParams } from "react-router-dom";
const Guide = () => {
    const {id} = useParams();
    if(id == 1)
    {
        return ( 
            <Layout2 pageType={"ငွေဖြည့်နည်း"}> 
                <Helmet>
                    <title>ငွေဖြည့်နည်း</title>
                </Helmet>
                    <div>
                        <p style={{color:"rgb(204,204,204)",textAlign:"center"}}>ငွေဖြည့်နည်း?</p> 
                        <iframe width="100%" height="345" src="https://www.youtube.com/embed/0tawngZk0NY?autoplay=1&mute=0"></iframe>  
 
                    </div>
            </Layout2>
        );
    }else if(id == 2) 
    {
        return ( 
            <Layout2 pageType={"ငွေထုတ်နည်း"}> 
                <Helmet>
                    <title>ငွေထုတ်နည်း</title>
                </Helmet>
                    <div>
                        <p style={{color:"rgb(204,204,204)",textAlign:"center"}}>ငွေထုတ်နည်း?</p>  
                        <iframe width="100%" height="345" src="https://www.youtube.com/embed/KezwO27tYAo?autoplay=1&mute=0"></iframe>      
                    </div>
            </Layout2>
        );
    }
}
 
export default Guide;