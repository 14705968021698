import React, { useState } from "react";
import Layout2 from "../layouts/Layout2";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet"

const Login = () => {
    const [username,setUsername] = useState('');
    const [password,setPassword] = useState('');
    const [isLoading,setIsLoading] = useState(false);
    const [submitted,setSubmitted] = useState(false);
    const navigate = useNavigate();
    const endpointURL =process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_MODE : process.env.REACT_APP_PRO_MODE;
    
    const handleLogin = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        if(!submitted){
            setSubmitted(true);
            try{
                fetch(endpointURL+"/api/v1/loginUser",{
                    method:"POST",
                    headers:{
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        username,password
                    }),
                })
                .then(response => {
                   return response.json();
                })
                .then(data => {
                    if(data.status === true)
                    {
                        localStorage.setItem('authenticated',true);
                        localStorage.setItem('token', data.token);
                        navigate("/");
                    }else
                    {
                        toast.error(data.msg,{position: toast.POSITION.TOP_CENTER,autoClose:false});
                    }
                }).catch(error => console.error('Error:', error))
                // const response = await fetch(endpointURL+"/api/v1/loginUser",{
                //     method:"POST",
                //     headers:{
                //         'Content-Type': 'application/json'
                //     },
                //     body: JSON.stringify({
                //         username,password
                //     }),
                // });
                // console.log(response);
                // if(!response.ok)
                // {
                //     throw new Error('Login failed');
                // }
                // const data = await response.json();
                // //console.log('Token:',data.token);
                // if(data.status === true)
                // {
                //     localStorage.setItem('authenticated',true);
                //     localStorage.setItem('token', data.token);
                //     navigate("/");
                // }else
                // {
                //     toast.error(data.msg,{position: toast.POSITION.TOP_CENTER,autoClose:false});
                // }
            }catch(error){
                console.error('Login failed: ',error.message);
            }
        }
        setSubmitted(false);
        setIsLoading(false);
    }
    const handleChange = (event) => { 
        const re = /^[0-9\b]+$/;
      if (event.target.value === '' || re.test(event.target.value)) {
         setUsername(event.target.value)
      } 
    };
    return ( 
        <Layout2 pageType={"အကောင့်ဝင်မည်"}>
            <Helmet>
                <title>အကောင့်ဝင်မည်</title>
            </Helmet>
            <form className="row" onSubmit={handleLogin}>
                <div className="col-12" style={{textAlign:"center"}}>
                    <img src="logo.png" style={{width:"130px"}} alt="Logo"></img>
                </div>
                <div className="form-group">
                    <div className="col-12">
                        <label htmlFor="phone">ဖုန်းနံပါတ် (09 ထည့်ရန်) <b className="text-danger">*</b></label>
                        <input type="number" id="phone" value={username} onChange={handleChange} className="form-control bg-dark text-white mt-2" placeholder="09 ပါရိုက်ထည့်ပါ" autoComplete="off"></input>
                    </div>
                </div>
                <div className="form-group mt-2">
                    <div className="col-12">
                        <label htmlFor="pin">Password <b className="text-danger">*</b></label>
                        <input type="password" id="pin" onChange={(e)=>setPassword(e.target.value)} className="form-control bg-dark text-white mt-2" placeholder="09 ပါရိုက်ထည့်ပါ" autoComplete="off"></input>
                    </div>
                </div>
                <div className="form-group mt-3 row">
                    <div className="col-6" style={{textAlign:"center"}}>
                        {/* <Link to={"/forget-password"}>Password မေ့နေပါသည်</Link> */}
                    </div>
                    <div className="col-6" style={{textAlign:"center"}}>
                        <Link to={"/register"}>အကောင့်ဖောက်မည်</Link>
                    </div>
                </div>
                <div className="form-group mt-5">
                    <div className="col-12" style={{textAlign:"center"}}>
                        <button type="submit" className="btn btn-md bg-warning"><i className="bx bx-right-arrow-alt text-white"></i>{isLoading?"Loading...":"လော့အင်ဝင်မည်"} </button>
                    </div>
                </div>
            </form>
            <div className="row mt-4">
                <div className="col-12 mt-4" style={{textAlign:"center"}}>
                   <i className="bx bx-arrow-back" style={{color:"rgb(204,204,204)"}}></i> 
                   <Link to={"/"} style={{color:"rgb(204,204,204)"}}>Home Page</Link>
                </div>
            </div>
        </Layout2>
     );
}
 
export default Login;