import {  useEffect, useState } from "react";
import Layout2 from "../layouts/Layout2";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
const WithdrawlPassword = () => {
    const endpointURL =process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_MODE : process.env.REACT_APP_PRO_MODE;
    const [password,setPassword] = useState("");
    const [isLoading,setIsLoading] = useState(false);
    const [stepOne,setStepOne] = useState(true);
    const [stepTwo,setStepTwo] = useState(false);
    const [submitted,setSubmitted] = useState(false);
    const [errorpassword,setErrorPassword] = useState("");
    const location = useLocation();
    const paytype = location.state?.pay_type;
    const navigate = useNavigate();
    const handlePassword = async(e) => {
        e.preventDefault();
        setIsLoading(true);
        setErrorPassword("");
        if(!submitted){
            setSubmitted(true);
            try{
              toast.info("လုပ်ဆောင်နေသည်..ခဏစောင့်ပါ",{position: toast.POSITION.TOP_CENTER,autoClose:1000});
              const loginArray = {
                "password":password
              };
              setPassword("");
              const response = await fetch(endpointURL+"/api/v1/withdrawl-password",{
                  method:"POST",
                  headers: { 
                    'Content-Type': 'application/json',
                    'Authorization':  'Bearer '+localStorage.getItem('token'),
                  },
                  body: JSON.stringify(loginArray),
              });
              const result = await response.json();
              if(response.status === 200)
              {
                if(result.status === true)
                {
                  setStepOne(false);
                  setStepTwo(true);
                }
              }else
              {
                setErrorPassword(result.data.password);
                toast.error(result.msg,{position: toast.POSITION.TOP_CENTER,autoClose:false});
              }
            }catch(error){
              toast.error("Error occurred!Try again",{position: toast.POSITION.TOP_CENTER,autoClose:false});
            }
        }
        setSubmitted(false);
        setIsLoading(false);
    }
    useEffect(()=>{
        if(localStorage.getItem('token') == null || localStorage.getItem('token')=="")
        {
            navigate("/login");
        }
    },[]);
    return ( 
        <Layout2 pageType={"ငွေထုတ်မည်"}>
            <Helmet>
                <title>Password ရိုက်ထည့်ပါ</title>
            </Helmet>
            { stepOne?
                <div>
                    <p style={{color:"rgb(204,204,204)",textAlign:"center"}}>ငွေထုတ်ရန်အတွက် Password ရိုက်ထည့်ပါ</p>
                    <p style={{color:"rgb(204,204,204)",textAlign:"center"}}>အဆင့် ၂ မှ ၁ </p>

                    <form onSubmit={handlePassword}>
                        <div className="row mb-3 mx-auto mt-4">
                            <div className="col-8" style={{ width: "100%" }}>
                                <input type="password" onChange={(e) => setPassword(e.target.value)} minLength={6} maxLength={16} className="col-11 form-control" placeholder="Password ရိုက်ထည့်ပါ" style={{textAlign:"center",fontWeight:"bold",padding:"12px",color:"gold",background:"white",border:"none",borderBottom:"2px solid #ccc",outline:"none"}}></input>
                                <label className="text-danger">{errorpassword}</label>
                            </div>
                        </div>
                        <div className="row mx-auto mt-3">
                            <div className="col-12" style={{ width: "100%",textAlign:'center' }}>
                                <button type="submit" className="btn btn-warning"><i className="bx bx-right-arrow-alt text-primary"></i> 
                                    {isLoading?"Loading..":"အတည်ပြု"}   
                                </button>
                            </div>
                        </div>
                    </form>
                </div>:null
            }
            {stepTwo?
                <StepTwo 
                    setStepOne={setStepOne} 
                    setStepTwo={setStepTwo} 
                    paytype={paytype}>
                </StepTwo>:null

            }
        </Layout2>
     );
}
 
export default WithdrawlPassword;

function StepTwo(props) {
    const endpointURL =process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_MODE : process.env.REACT_APP_PRO_MODE;
    const [withdrawlAmount,setWithdrawlAmount] = useState("");
    const [submitted,setSubmitted] = useState(false);
    const [isLoading,setIsLoading] = useState(false);
    const navigate = useNavigate();
    const handleStepOne = event => {
        props.setStepOne(true);
        props.setStepTwo(false);
    };
    const handleAmount = (money) => {
        setWithdrawlAmount(money);
    }
    const handleApplyWithdrawl = async (e) => {
        setIsLoading(true);
        if(parseInt(withdrawlAmount) > 0)
        {
            const withdrawlArray = {
                "pay_type" : props.paytype,
                "withdrawl_amount": withdrawlAmount
            };
            setWithdrawlAmount("");
           // console.log(withdrawlArray);
            if(!submitted){
                setSubmitted(true);
                try{
                  toast.info("လုပ်ဆောင်နေသည်..ခဏစောင့်ပါ",{position: toast.POSITION.TOP_CENTER,autoClose:1000});
                  const response = await fetch(endpointURL+"/api/v1/wallet-withdrawl",{
                      method:"POST",
                      headers: { 
                        'Content-Type': 'application/json',
                        'Authorization':  'Bearer '+localStorage.getItem('token'),
                      },
                      body: JSON.stringify(withdrawlArray),
                  });
                  const result = await response.json();
                  if(result.status === true)
                  {
                    toast.success(result.msg,{position: toast.POSITION.TOP_CENTER,autoClose:1000});
                    setTimeout(() => {
                        navigate("/");
                    }, 1200);
                  }else
                  {
                    toast.error(result.msg,{position: toast.POSITION.TOP_CENTER,autoClose:false});
                  }
                }catch(error){
                  toast.error("Error occurred!Try again",{position: toast.POSITION.TOP_CENTER,autoClose:false});
                }  
            }
        }else
        {
            toast.error("ငွေပမာဏထည့်သွင်းပါ",{position: toast.POSITION.TOP_CENTER,autoClose:false});
        }
        setSubmitted(false);
        setIsLoading(false);
    }
    return (
        <div>
            <Helmet>
                <title>ငွေပမာဏ ရွေးပါ</title>
            </Helmet>
            <a href="javascript:void(0);" style={{color:"rgb(204,204,204)",cursor:"pointer"}} onClick={handleStepOne}><i className="bx bx-arrow-back" style={{color:"rgb(204,204,204)"}}></i> အဆင့် ၁ သို့</a>
            <p style={{color:"rgb(204,204,204)",textAlign:"center"}}>ငွေထုတ်မည့်ပမာဏကိုရွေးပါ</p>
            <p style={{color:"rgb(204,204,204)",textAlign:"center",fontSize:"13px"}}>အဆင့် ၂ မှ ၂ </p>
                <div className="row mb-2">
                    <div className="col-12" style={{ width: "100%" }}>
                        <input type="text" readOnly defaultValue={withdrawlAmount} className="col-11 form-control"    placeholder="1000 ကျပ်မှစပြီးငွေထုတ်ပါ"  style={{textAlign:"center",fontWeight:"bold",padding:"12px",color:"gold",background:"white",border:"none",borderBottom:"2px solid #ccc",outline:"none"}}></input>
                    </div>
                </div>
                <div className="row mx-auto">
                    <div className="col-12 text-center" style={{ width: "100%" }}>
                        <button className="btn btn-outline-primary" onClick={() => handleAmount(10000)} style={{marginRight:"9px",marginBottom:"10px",width:"30%"}}>10000</button>
                        <button className="btn btn-outline-primary" onClick={() => handleAmount(15000)} style={{marginRight:"9px",marginBottom:"10px",width:"30%"}}>15000</button>
                        <button className="btn btn-outline-primary" onClick={() => handleAmount(20000)} style={{marginRight:"9px",marginBottom:"10px",width:"30%"}}>20000</button>
                    </div>
                    <div className="col-12 text-center" style={{ width: "100%" }}>
                        <button className="btn btn-outline-primary" onClick={() => handleAmount(30000)} style={{marginRight:"9px",marginBottom:"10px",width:"30%"}}>30000</button>
                        <button className="btn btn-outline-primary" onClick={() => handleAmount(50000)} style={{marginRight:"9px",marginBottom:"10px",width:"30%"}}>50000</button>
                        <button className="btn btn-outline-primary" onClick={() => handleAmount(60000)} style={{marginRight:"9px",marginBottom:"10px",width:"30%"}}>60000</button>
                    </div>
                    <div className="col-12 text-center" style={{ width: "100%" }}>
                        <button className="btn btn-outline-primary" onClick={() => handleAmount(70000)} style={{marginRight:"9px",marginBottom:"10px",width:"30%"}}>70000</button>
                        <button className="btn btn-outline-primary" onClick={() => handleAmount(80000)} style={{marginRight:"9px",marginBottom:"10px",width:"30%"}}>80000</button>
                        <button className="btn btn-outline-primary" onClick={() => handleAmount(100000)} style={{marginRight:"9px",marginBottom:"10px",width:"30%"}}>100000</button>
                    </div>
                    <div className="col-12 text-center" style={{ width: "100%" }}>
                        <button className="btn btn-outline-primary" onClick={() => handleAmount(150000)} style={{marginRight:"9px",marginBottom:"10px",width:"30%"}}>150000</button>
                        <button className="btn btn-outline-primary" onClick={() => handleAmount(200000)} style={{marginRight:"9px",marginBottom:"10px",width:"30%"}}>200000</button>
                        <button className="btn btn-outline-primary" onClick={() => handleAmount(300000)} style={{marginRight:"9px",marginBottom:"10px",width:"30%"}}>300000</button>
                    </div>
                    <div className="col-12 text-center" style={{ width: "100%" }}>
                        <button className="btn btn-outline-primary" onClick={() => handleAmount(400000)} style={{marginRight:"9px",marginBottom:"10px",width:"30%"}}>400000</button>
                        <button className="btn btn-outline-primary" onClick={() => handleAmount(500000)} style={{marginRight:"9px",marginBottom:"10px",width:"30%"}}>500000</button>
                        <button className="btn btn-outline-primary" onClick={() => handleAmount(600000)} style={{marginRight:"9px",marginBottom:"10px",width:"30%"}}>600000</button>
                    </div>
                    <div className="col-12 text-center" style={{ width: "100%" }}>
                        <button className="btn btn-outline-primary" onClick={() => handleAmount(700000)} style={{marginRight:"9px",marginBottom:"10px",width:"30%"}}>700000</button>
                        <button className="btn btn-outline-primary" onClick={() => handleAmount(800000)} style={{marginRight:"9px",marginBottom:"10px",width:"30%"}}>800000</button>
                        <button className="btn btn-outline-primary" onClick={() => handleAmount(1000000)} style={{marginRight:"9px",marginBottom:"10px",width:"30%"}}>1000000</button>
                    </div>
                </div>
                <div className="row mx-auto mt-3">
                    <div className="col-12" style={{ width: "100%",textAlign:'center' }}>
                        <button type="button" onClick={()=>handleApplyWithdrawl()} className="btn btn-warning">
                            <i className="bx bx-right-arrow-alt text-primary"></i> 
                            {isLoading?"Loading...":"အတည်ပြု"}
                        </button>
                    </div>
                </div>
        </div>
    );
}