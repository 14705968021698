import { useEffect, useState } from "react";
import Layout2 from "../layouts/Layout2";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
const Register = () => {
    const endpointURL =process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_MODE : process.env.REACT_APP_PRO_MODE;
    const [name,setName] = useState("");
    const [username,setUsername] = useState("");
    const [isLoading,setIsLoading] = useState(false);
    const [errorname,setErrorName] = useState("");
    const [errorphone,setErrorPhone] = useState("");
    const [submitted,setSubmitted] = useState(false);
    const navigate = useNavigate();
    const handleRegister = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setErrorName("");
        setErrorPhone("");
        const registerArray = {
            "phone":username,
            "name":name
        };
        if(!submitted){
            setSubmitted(true);
            try{
                const response = await fetch(endpointURL+"/api/v1/otp-request?apiKey=shwekyee",{
                    method:"POST",
                    headers:{
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(registerArray),
                });
                if(!response.ok)
                {
                    throw new Error('Request failed');
                }
                const resp = await response.json();
                //console.log(resp);
                if(resp.status === false)
                {
                    setErrorName(resp.data.name); 
                    setErrorPhone(resp.data.phone); 
                    toast.error(resp.msg,{position: toast.POSITION.TOP_CENTER,autoClose:false});
                }else
                {
                    //setRequestId(resp.data.request_id);
                    navigate('/otp-verify', {state:{name:name,username:username,request_id:resp.data.request_id}});
                }
            }catch(error){
                toast.error(error.message,{position: toast.POSITION.TOP_CENTER,autoClose:false});
                console.error('register failed: ',error.message);
            }
            setIsLoading(false);
            setSubmitted(false);
        }
    }
    const handleChange = (event) => { 
        const re = /^[0-9\b]+$/;
      if (event.target.value === '' || re.test(event.target.value)) {
         setUsername(event.target.value)
      } 
    };
    useEffect(() =>{
       // handleRegister();
       console.count('render count:');
    },[]);
    return ( 
        <Layout2 pageType={"အကောင့်သစ်ဖောက်မည်"}>
            <Helmet>
                <title>အကောင့်ဖောက်မည်</title>
            </Helmet>
            <form className="row" onSubmit={handleRegister}>
                <div className="col-12" style={{textAlign:"center"}}>
                    <img src="logo.png" style={{width:"130px"}} alt="Logo"></img>
                </div>
                <div className="form-group">
                    <div className="col-12">
                        <label htmlFor="name">အမည် <b className="text-danger">*</b></label>
                        <input type="text" id="name" onChange={(e) => setName(e.target.value)} className="form-control bg-dark text-white mt-2" placeholder="အမည်ရိုက်ထည့်ပါ" autoComplete="off"></input>
                        <label htmlFor="name" style={{color:"red"}}>{errorname}</label>
                    </div>
                </div>
                <div className="form-group">
                    <div className="col-12">
                        <label htmlFor="phone"> ဖုန်းနံပါတ် <b className="text-danger">*</b></label>
                        <input type="number" id="phone" onChange={handleChange} className="form-control bg-dark text-white mt-2" placeholder="09 ပါရိုက်ထည့်ပါ" autoComplete="off"></input>
                        <label htmlFor="phone" style={{color:"red"}}>{errorphone}</label>
                    </div>
                </div>
                <div className="form-group mt-3 row">
                    <div className="col-12" style={{textAlign:"right"}}>
                        <Link to={"/login"}>အကောင့်ဝင်မည်</Link>
                    </div>
                </div>
                <div className="form-group mt-4">
                    <div className="col-12" style={{textAlign:"center"}}>
                        <button type="submit" className="btn btn-md bg-warning"><i className="bx bx-right-arrow-alt text-white"></i>{isLoading?"Loading":"ရှေ့ဆက်မည်"}</button>
                    </div>
                </div>
            </form>
        </Layout2>
     );
}
 
export default Register;
