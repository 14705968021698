import { useEffect, useState } from "react";
import Layout2 from "../layouts/Layout2";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import ColorMean from "../components/ColorMean";
import { isMobile } from 'react-device-detect';
import ThreeBetList from "../components/ThreeBetList";
import { Helmet } from "react-helmet";
const ThreeHomeCreate = () => {
    const endpointURL =process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_MODE : process.env.REACT_APP_PRO_MODE;
    const [numbers,setNumbers] = useState([]);
    const [defaultnumbers,setDefaultNumbers] = useState([]);
    const [selected, setselected] = useState([]);
    const [betamount,setBetAmount] = useState(100);
    const [closeTime,setCloseTime] = useState("");
    const [breakTime,setBreakTime] = useState(false);
    const [isLoading,setIsLoading] = useState(false);
    const [isLoadingData,setIsLoadingData] = useState(false);
    const [data,setData] = useState([]);
    const [show, setShow] = useState(false);
    const [showbetmodal, setBetModalShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [activeElement, setActiveElement] = useState(null);
    const navigate = useNavigate();
    const fetchData = async () => {
        try {
          const response = await fetch(endpointURL+'/api/v1/three-numbers',
                {
                    headers: {'Authorization':  'Bearer '+localStorage.getItem('token') }
                }
            );
          const result = await response.json();
         // console.log(result);
          if(result.status === false)
          {
            setBreakTime(true);
          }else
          {
            
            setNumbers(result.data);
            setCloseTime(result.closeTime);
            const filterSelection = result.data.filter(item => item.number >= 0 && item.number <= 99);
            setDefaultNumbers(filterSelection);
            //console.log("get-number /"+numbers);
          }
        } catch (error) {
          console.error('fetch number error:', error);
        }
        setIsLoading(false);
    }
    const filterNumbers = (start,end,element) => {
      const filterSelection = numbers.filter(item => item.number >= start && item.number <= end);
      setDefaultNumbers(filterSelection);
      setActiveElement(element);
    }
    const getColor = (element) => {
      return activeElement === element ? 'green' : 'gray';
    };
    const loginData = async () => {
        try {
          const headers = { 'Authorization':  'Bearer '+localStorage.getItem('token') };
          const response = await fetch(endpointURL+'/api/v1/loginData',{headers});
          const result = await response.json();
          setData(result.data);
        } catch (error) {
          console.error('fetch data error:', error);
        }
        setIsLoadingData(false);
    };
    const handleNumber = (number) => {
        if(selected.filter(e => e.number === number).length === 0) {
          setselected([...selected, {number:number,amount:betamount}]);
        } else {
          setselected(selected.filter((select) => select.number !== number));
        }
      };
    const handleColor = (percent) =>{
        if(percent <= 50)
        {
          return "green";
        }else if(percent <= 90)
        {
          return "yellow";
        }else if(percent >90 && percent <= 99)
        {
          return "red";
        }else{
          return "aqua";
        }
      }
    const handleR = () => {
        var digitRArray = [];
         selected.forEach((digit,index) => {
              let digitR = digit.number;
              let digitReverseA = digitR[0]+""+digitR[2]+""+digitR[1];
              let digitReverseB = digitR[1]+""+digitR[0]+""+digitR[2];
              let digitReverseC = digitR[1]+""+digitR[2]+""+digitR[0];
              let digitReverseD = digitR[2]+""+digitR[0]+""+digitR[1];
              let digitReverseE = digitR[2]+""+digitR[1]+""+digitR[0];
             if(selected.filter(e => e.number === digitReverseA).length === 0) {
               digitRArray.push({number:digitReverseA,amount: betamount});
               console.log("1")
             }
             if(digitReverseB !== digitReverseC)
             {
              if(selected.filter(e => e.number === digitReverseB).length === 0) {
                digitRArray.push({number:digitReverseB,amount: betamount});
                console.log("2")
              }
             }
             
              if(digitReverseA !== digitReverseC)
              {
                if(selected.filter(e => e.number === digitReverseC).length === 0) {
                  digitRArray.push({number:digitReverseC,amount: betamount});
                  console.log("3")
                }
              }
              if(digitReverseA !== digitReverseD && digitReverseB !== digitReverseD)
              {
                if(selected.filter(e => e.number === digitReverseD).length === 0) {
                  digitRArray.push({number:digitReverseD,amount: betamount});
                  console.log("4")
                }
              }
              
              if(digitReverseD !== digitReverseE && digitReverseC !== digitReverseE)
              {
                if(selected.filter(e => e.number === digitReverseE).length === 0) {
                  digitRArray.push({number:digitReverseE,amount: betamount});
                  console.log("5")
                }
              }
         });
         setselected([...selected,...digitRArray]);//spread operator
     }  
     const betListShow = () => {
      if(betamount > 0)
      {
        if(selected.length > 0)
        {
          setBetModalShow(true);
        }
      }
    }
    const changeBetAmount = (e) =>{
      const numberLists = selected.map(list => {
          return {...list,amount:e.target.value}
      });
      // Re-render with the new array
      setBetAmount(e.target.value);
      setselected(numberLists);
    }
    //console.log(selected);
    useEffect(() => {
        setIsLoading(true);
        setIsLoadingData(true);
        console.count("render count");
        if(localStorage.getItem('token') === null || localStorage.getItem('token')==="")
        {
          navigate("/");
        }
        fetchData();
        loginData();
        
      },[]);
    return ( 
        <Layout2 pageType={"သုံးလုံး မှကြိုဆိုပါသည်"}>
            <Helmet>
                <title>3D ထိုးမည်</title>
            </Helmet>
            <div className="row mt-0">
                <div className="col-12 mt-1 mb-1 text-center">
                    <input
                        type="number"
                        placeholder="အနည်းဆုံး ၁၀၀ ကျပ်"
                        defaultValue={betamount}
                        onChange={changeBetAmount}
                        style={{
                            width: "95%",
                            border: "none",
                            padding: "7px",
                            background: "#0f0f0f",
                            outline: "none",
                            borderBottom: "2px solid white",
                        }}
                    ></input>
                </div>
            </div>
            <div className="row text-center">
                <div className="col-6" style={{ marginTop: "7px" }}>
                    <i className="lni lni-wallet"></i>
                    <span style={{ fontSize: "14px", paddingLeft: "7px" }}>
                        {!isLoadingData ? data.unit +" ကျပ်" :"Loading..." } 
                    </span>
                </div>
                <div className="col-6">
                <span style={{ fontSize: "13px" }}>ထီပိတ်ချိန်</span>
                    <br />
                    <span style={{ fontSize: "13px" }}>{closeTime}</span>
                </div>
                <div className="col-6 p-2">
                    {/* <button className="btn btn-md btn-outline-success rounded-pill" style={{ width: "65%",marginRight:"3px" }}>အမြန်ရွေး</button> */}
                    <button onClick={(e)=>handleR()} className="btn btn-md btn-outline-info rounded-pill"  style={{ width: "95%" }}>R</button>
                </div>
                <div className="col-6 p-2">
                <button className="btn btn-md btn-warning rounded-pill" style={{ width: "95%" }} onClick={betListShow}>
                    ထိုးမည်
                </button>
                </div>
                <div className="col-6 p-1">
                    <a href="#" style={{ color: "#0070E0",fontSize:"12px" }} onClick={handleShow}>
                        အရောင်အဓိပ္ပါယ်{" "}
                    </a>
                </div>
                <div className="col-6 p-1">
                  <a href={"/three-home/create"} style={{ color: "#0070E0",fontSize:"12px" }} >အားလုံးရှင်းမည်</a>
                </div>
            </div>
            <div className="row  mx-auto selection-div" style={{backgroundColor:"#444"}}>
                  <div onClick={(e) => filterNumbers(0,99,'element1')} style={{width:isMobile?"25%":"20%",color: getColor('element1')}}>
                      000-099
                  </div>
                  <div  onClick={(e) => filterNumbers(100,199,'element2')} style={{width:isMobile?"25%":"20%",color: getColor('element2')}} >
                    100-199
                  </div>
                  <div  onClick={(e) => filterNumbers(200,299,'element3')} style={{width:isMobile?"25%":"20%",color: getColor('element3')}}>
                    200-299
                  </div>
                  <div  onClick={(e) => filterNumbers(300,399,'element4')} style={{width:isMobile?"25%":"20%",color: getColor('element4')}}>
                    300-399
                  </div>
                  <div  onClick={(e) => filterNumbers(400,499,'element5')} style={{width:isMobile?"25%":"20%",color: getColor('element5')}}>
                    400-499
                  </div>
                  <div  onClick={(e) => filterNumbers(500,599,'element6')} style={{width:isMobile?"25%":"20%",color: getColor('element6')}}>
                    500-599
                  </div>
                  <div  onClick={(e) => filterNumbers(600,699,'element7')} style={{width:isMobile?"25%":"20%",color: getColor('element7')}}>
                    600-699
                  </div>
                  <div  onClick={(e) => filterNumbers(700,799,'element8')} style={{width:isMobile?"25%":"20%",color: getColor('element8')}}>
                    700-799
                  </div>
                  <div  onClick={(e) => filterNumbers(800,899,'element9')} style={{width:isMobile?"25%":"20%",color: getColor('element9')}}>
                    800-899
                  </div>
                  <div  onClick={(e) => filterNumbers(900,999,'element10')} style={{width:isMobile?"25%":"20%",color: getColor('element10')}}>
                    900-999
                  </div> 
            </div>
            <div className="row text-center mt-1" style={{position:"absolute",height:isMobile?"48%":"55%",maxHeight:isMobile?"48%":"55%",overflowY:"auto"}}>
                <div className="col-12  pb-2" style={{ width: "100%",boxSizing:"border-box" }}>
                    {!isLoading ?
                        defaultnumbers.map((number) => (
                        <button
                            key={number.number}
                            onClick={() => number.percent == 100? "":handleNumber(number.number)}
                            className="btn-number"
                            style={{backgroundColor: selected.filter(e => e.number === number.number).length > 0 ? "#6c757d":"#0f0f0f",
                                    width: isMobile?"17%":"18%",
                                    padding: "12px 10px 12px 10px",
                                    margin: "4px",
                                    fontWeight:"bold",
                                    borderRadius: "7px",
                                    borderTop: "3px solid "+number.percent === 100? "aqua":"#ddd",
                                    borderLeft: "3px solid "+number.percent === 100? "aqua":"#ddd",
                                    borderRight: "3px solid #ddd",
                                    borderBottom:"5px solid "+handleColor(number.percent),
                                    boxSizing:"border-box"
                                }}
                            >
                            {number.number}
                        </button>
                        ))
                        :(
                            <div className="p-4 text-center mx-auto" style={{width:"100%"}}>
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>  လုပ်ဆောင်နေသည်...ခဏစောင့်ပါ
                            </div>
                        )
                    }
                    { breakTime ? 
                      <div className="text-danger  p-4" style={{border:"1px solid #0d64ce",marginTop:"100px",borderRadius:"5px"}}>
                        <i className="lni lni-timer text-danger"></i> Break Time ဖြစ်ပါသည်
                    </div>:""
                    }
                </div>
            </div>
            <ColorMean show={show} onHide={()=> handleClose()}></ColorMean>
            <ThreeBetList  
                    selected={selected} 
                    setselected={setselected} 
                    betamount={betamount} 
                    show={showbetmodal} 
                    setBetModalShow={setBetModalShow}
                    onHide={()=> setBetModalShow(false)}>
                    
            </ThreeBetList>
      </Layout2>
     );
}
 
export default ThreeHomeCreate;