import { Link, useNavigate} from 'react-router-dom';
import { isMobile } from 'react-device-detect';
const Header2 = ({type}) => {
    const pathname = window.location.pathname;
    let navigate = useNavigate();
    return (  
        <nav className="page-header" style={{marginTop:isMobile?"28px":""}}>
            <div className="row">
                <div className="col-2">
                    <Link onClick={() =>  navigate(-1)}>
                        <i className='bx bx-arrow-back'></i>
                    </Link>
                </div>
                <div className="col-8" style={{textAlign:"center"}}>
                    {type}
                </div>
                <div className="col-2" style={{textAlign:"right"}}>
                    <a href={pathname}>
                        <i className='lni lni-reload'></i>
                    </a>
                </div>
            </div>
        </nav>
    );
}
 
export default Header2;