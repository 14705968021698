import { useEffect, useState } from "react";
import Layout2 from "../layouts/Layout2";
import { toast } from "react-toastify";
import { Spinner,  Table } from "react-bootstrap";
import { Helmet } from "react-helmet";
const TwoLuckyList = () => {
    const endpointURL =process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_MODE : process.env.REACT_APP_PRO_MODE;
    const [data,setData] = useState([]);
    const [isLoading,setIsLoading] = useState(false);
    const [date,setDate] = useState("");
    const [time,setTime] = useState("");
    const [number,setNumber] = useState("");
    const fetchData = async (type) => {
        setIsLoading(true);
        const walletArray = {
        };
        try{
            const response = await fetch(endpointURL+"/api/v1/two-lucky-list",{
                method:"POST",
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization':  'Bearer '+localStorage.getItem('token'),
                },
                body: JSON.stringify(walletArray),
            });
            if(!response.ok)
            {
                toast.error("Response error",{position: toast.POSITION.TOP_CENTER,autoClose:false});
            }
            const result = await response.json();
            console.log(result);
            if(result.status == true)
            {
                //console.log(result.data);
                setData(result.data);
                setDate(result.date);
                setTime(result.time);
                setNumber(result.number);
            }else
            {
                toast.error(result.msg,{position: toast.POSITION.TOP_CENTER,autoClose:false});
            }
        }catch(error){
            toast.error("Error occurred!Try again",{position: toast.POSITION.TOP_CENTER,autoClose:false});
        }
        setIsLoading(false);
    }
    useEffect(()=>{
        fetchData();
    },[]);
    return ( 
        <Layout2 pageType={"ထီပေါက်သူများ"}>
            <Helmet>
                <title>2D Lucky Page</title>
            </Helmet>
                {
                    isLoading ? 
                        <div style={{textAlign:"center"}}>
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>  လုပ်ဆောင်နေသည်...ခဏစောင့်ပါ
                        </div>
                    :
                    data.length > 0 ?
                    <div>
                        {
                            <div  style={{borderRadius:"10px",textAlign:"center",padding:"0px",objectFit:'contain',width:"100%",height:"190px",backgroundImage:`url(../winner.gif)`}}>
                                <p style={{padding:"0"}}>
                                    <i className="bx bx-trophy" style={{fontSize:"70px",padding:"0px"}}></i><br/>
                                    <span style={{color:"rgb(204,204,204)",fontSize:"13px",marginRight:"10px"}}>ထီထွက်ချိန်</span>
                                    <span style={{marginRight:"50px",color:"rgb(204,204,204)"}}>{date}</span>
                                    <span style={{marginRight:"20px",color:"rgb(204,204,204)"}}>{time}</span>
                                    <span style={{fontSize:"50px",color:"green",fontWeight:"bolder"}}>{number}</span>
                                </p>
                            </div>  
                        }
                        <h6>အများဆုံးထီပေါက်သူ(၁၀)ယောက်သာဖော်ပြပါသည်</h6>
                        <Table striped  hover  style={{width:"100%",color:"rgb(204,204,204)"}}  variant="dark">
                            <thead>
                                <tr>
                                    <th style={{fontSize:"13px",textAlign:"center"}}>စဉ်</th>
                                    <th style={{fontSize:"13px",textAlign:"center"}}>အမည်</th>
                                    <th style={{fontSize:"13px",textAlign:"center"}}>ထိုးကြေးငွေ(ကျပ်)</th>
                                    <th style={{fontSize:"13px",textAlign:"center"}}>အနိုင်ရငွေ(ကျပ်)</th>
                                </tr>
                            </thead>
                            <tbody style={{fontSize:"13px"}}>
                                {
                                    data.map((list,index)=>(
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>
                                                {list.name}<br/>
                                                {list.username}
                                            </td>
                                            <td style={{textAlign:"right"}}>{list.bet_amount}</td>
                                            <td style={{textAlign:"right"}}>{list.bet_price}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </div>
                    :
                    <div className="mx-auto p-2" style={{width:"100%",color:"rgb(204,204,204)",textAlign:"center"}}>
                        <p>မှတ်တမ်းမရှိပါ...</p>
                    </div>    
                }
        </Layout2>
     );
}
 
export default TwoLuckyList;