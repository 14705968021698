import { Link, useNavigate } from "react-router-dom";
import Layout2 from "../layouts/Layout2";
import { Stack, Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
const Deposit = () => {
    const [stepOne,setStepOne] = useState(true);
    const [stepTwo,setStepTwo] = useState(false);
    const [stepThree,setStepThree] = useState(false);
    const [pay,setPay] = useState("");
    const [amount,setAmount] = useState("");
    const navigate = useNavigate("");
    const handleSecondPage = (payname) => {
        setPay(payname);
        setStepOne(false);
        setStepTwo(true);
    };
    useEffect(()=>{
        if(localStorage.getItem('token') == null || localStorage.getItem('token')=="")
        {
            alert('Login ဝင်ရောက်ပါ...');
            navigate("/");
        }
    });
    return ( 
        <Layout2 pageType={"ငွေဖြည့်မည်"}>
            <Helmet>
                <title>ငွေဖြည့်မည်</title>
            </Helmet>
            { stepOne ? (
                <div>
                    <p style={{color:"rgb(204,204,204)",textAlign:"center"}}>ငွေဖြည့်မည့်အမျိုးအစားရွေးပါ</p>
                    <p style={{color:"rgb(204,204,204)",textAlign:"center"}}>အဆင့် ၃ မှ ၁</p>

                    <Stack gap={4} className="mt-2 mx-auto">
                        <Button variant="outline-secondary  p-3" onClick={()=> handleSecondPage("KPAY")}>  
                            <img src="../kpay.png" style={{width:"40px",marginRight:"10px"}} alt="Logo"></img>
                            <Link> KBZ Pay ဖြင့်ငွေဖြည့်မည် </Link>
                        </Button>
                        <Button variant="outline-secondary p-3" onClick={() => handleSecondPage("WAVE")}>  
                            <img src="../wave.png" style={{width:"40px",marginRight:"10px"}} alt="Logo"></img> 
                            <Link>Wave Pay ဖြင့်ငွေဖြည့်မည်</Link> 
                        </Button>
                    </Stack>
                </div>
                ):null
            }
            { 
                stepTwo && <StepTwo  pay={pay} amount={amount} setAmount={setAmount}   setStepOne={setStepOne} stepOne={stepOne} stepTwo={stepTwo} setStepTwo={setStepTwo} setStepThree={setStepThree}></StepTwo>
            }
            {
                stepThree && <StepThree pay={pay} amount={amount} setAmount={setAmount}  setStepOne={setStepOne} setStepTwo={setStepTwo} setStepThree={setStepThree}></StepThree>
            }
        </Layout2>
     );
}
export default Deposit;

function StepTwo(props) {
    const handleAmount = (money) =>{
        props.setAmount(money);
        
    }
    const handleFirstPage = event => {
        props.setStepOne(true);
        props.setStepTwo(false);
        props.setAmount("");
    };
    const handleThirdPage = event => {
        if(parseInt(props.amount) > 0)
        {
            props.setStepOne(false);
            props.setStepTwo(false);
            props.setStepThree(true);
        }else
        {
            toast.error("ငွေပမာဏထည့်သွင်းပါ",{position: toast.POSITION.TOP_CENTER,autoClose:false});
        }
    }
    const handleChange = (e) => {
        const regex = /^[0-9\b]+$/;
        if (e.target.value === "" || regex.test(e.target.value)) {
          props.setAmount(e.target.value);
        }
      };
    return (
        <div>
            <Helmet>
                <title>ငွေပမာဏရိုက်ထည့်ပါ</title>
            </Helmet>
            <a href="javascript:void(0);" style={{color:"rgb(204,204,204)",cursor:"pointer"}} onClick={handleFirstPage}><i className="bx bx-arrow-back" style={{color:"rgb(204,204,204)"}}></i> အဆင့် ၁ သို့</a>
            <p style={{color:"rgb(204,204,204)",textAlign:"center"}}>ငွေဖြည့်မည့်ပမာဏကိုဖြည့်ပါ</p>
            <p style={{color:"rgb(204,204,204)",textAlign:"center",fontSize:"13px"}}>အဆင့် ၃ မှ ၂ </p>
            <div className="row mb-2">
                <div className="col-12" style={{ width: "100%" }}>
                    <input type="text" readOnly pattern="[0-9]*" defaultValue={props.amount}  className="col-11 form-control"    placeholder="1000 ကျပ်မှစပြီးငွေဖြည့်ပါ"  style={{textAlign:"center",fontWeight:"bold",padding:"12px",color:"gold",background:"white",border:"none",borderBottom:"2px solid #ccc",outline:"none"}}></input>
                </div>
                {/* onChange={(e)=>props.setAmount((v) => (e.target.validity.valid ? e.target.value : v))}  */}
            </div>
            <div className="row mx-auto">
                <div className="col-12 text-center" style={{ width: "100%" }}>
                    <button className="btn btn-outline-primary" onClick={() => handleAmount(1000)} style={{marginRight:"9px",marginBottom:"10px",width:"30%"}}>1000</button>
                    <button className="btn btn-outline-primary" onClick={() => handleAmount(2000)} style={{marginRight:"9px",marginBottom:"10px",width:"30%"}}>2000</button>
                    <button className="btn btn-outline-primary" onClick={() => handleAmount(3000)} style={{marginRight:"9px",marginBottom:"10px",width:"30%"}}>3000</button>
                </div>
                <div className="col-12 text-center" style={{ width: "100%" }}>
                    <button className="btn btn-outline-primary" onClick={() => handleAmount(5000)} style={{marginRight:"9px",marginBottom:"10px",width:"30%"}}>5000</button>
                    <button className="btn btn-outline-primary" onClick={() => handleAmount(10000)} style={{marginRight:"9px",marginBottom:"10px",width:"30%"}}>10000</button>
                    <button className="btn btn-outline-primary" onClick={() => handleAmount(15000)} style={{marginRight:"9px",marginBottom:"10px",width:"30%"}}>15000</button>
                    
                </div>
                <div className="col-12 text-center" style={{ width: "100%" }}>
                    <button className="btn btn-outline-primary" onClick={() => handleAmount(20000)} style={{marginRight:"9px",marginBottom:"10px",width:"30%"}}>20000</button>
                    <button className="btn btn-outline-primary" onClick={() => handleAmount(30000)} style={{marginRight:"9px",marginBottom:"10px",width:"30%"}}>30000</button>
                    <button className="btn btn-outline-primary" onClick={() => handleAmount(50000)} style={{marginRight:"9px",marginBottom:"10px",width:"30%"}}>50000</button>
                </div>
                <div className="col-12 text-center" style={{ width: "100%" }}>
                    <button className="btn btn-outline-primary" onClick={() => handleAmount(60000)} style={{marginRight:"9px",marginBottom:"10px",width:"30%"}}>60000</button>
                    <button className="btn btn-outline-primary" onClick={() => handleAmount(70000)} style={{marginRight:"9px",marginBottom:"10px",width:"30%"}}>70000</button>
                    <button className="btn btn-outline-primary" onClick={() => handleAmount(80000)} style={{marginRight:"9px",marginBottom:"10px",width:"30%"}}>80000</button>
                </div>
                <div className="col-12 text-center" style={{ width: "100%" }}>
                    <button className="btn btn-outline-primary" onClick={() => handleAmount(100000)} style={{marginRight:"9px",marginBottom:"10px",width:"30%"}}>100000</button>
                    <button className="btn btn-outline-primary" onClick={() => handleAmount(150000)} style={{marginRight:"9px",marginBottom:"10px",width:"30%"}}>150000</button>
                    <button className="btn btn-outline-primary" onClick={() => handleAmount(200000)} style={{marginRight:"9px",marginBottom:"10px",width:"30%"}}>200000</button>
                </div>
                <div className="col-12 text-center" style={{ width: "100%" }}>
                    <button className="btn btn-outline-primary" onClick={() => handleAmount(300000)} style={{marginRight:"9px",marginBottom:"10px",width:"30%"}}>300000</button>
                    <button className="btn btn-outline-primary" onClick={() => handleAmount(400000)} style={{marginRight:"9px",marginBottom:"10px",width:"30%"}}>400000</button>
                    <button className="btn btn-outline-primary" onClick={() => handleAmount(500000)} style={{marginRight:"9px",marginBottom:"10px",width:"30%"}}>500000</button>
                </div>
                <div className="col-12 text-center" style={{ width: "100%" }}>
                    <button className="btn btn-outline-primary" onClick={() => handleAmount(700000)} style={{marginRight:"9px",marginBottom:"10px",width:"30%"}}>700000</button>
                    <button className="btn btn-outline-primary" onClick={() => handleAmount(800000)} style={{marginRight:"9px",marginBottom:"10px",width:"30%"}}>800000</button>
                    <button className="btn btn-outline-primary" onClick={() => handleAmount(1000000)} style={{marginRight:"9px",marginBottom:"10px",width:"30%"}}>1000000</button>
                </div>
            </div>
            <div className="row mx-auto mt-3">
                <div className="col-12" style={{ width: "100%",textAlign:'center' }}>
                    <button className="btn btn-warning" onClick={handleThirdPage}><i className="bx bx-right-arrow-alt text-primary"></i> နောက်တဆင့်သို့</button>
                </div>
            </div>
        </div>
    );
}
function StepThree(props)
{
    const endpointURL =process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_MODE : process.env.REACT_APP_PRO_MODE;
    const [transactionNo,setTransactionNo] = useState("");
    const [submitted,setSubmitted] = useState(false);
    const [phone,setPhone] = useState("");
    const [helperid,setHelperId] = useState("");
    const [copySuccess, setCopySuccess] = useState('');
    // const copyToClipBoard = async () => {
    //     try {
    //          await navigator.clipboard.writeText(phone);
    //         // await navigator.clipboard.writeText(textToCopy);
    //         setCopySuccess('Copied!');
    //         toast.success("Copied!",{position: toast.POSITION.TOP_CENTER,autoClose:500});
    //     } catch (err) {
    //         setCopySuccess('Failed to copy!');
    //         toast.success(copySuccess,{position: toast.POSITION.TOP_CENTER,autoClose:1000});
    //     }
    // }
   const copyText = async (e) => {
            const textToCopy = phone;
            // Create a temporary input element
            const input = document.createElement('input');
            input.style.position = 'fixed';
            input.style.opacity = 0;
            input.value = textToCopy;
            // Append the input element to the DOM
            document.body.appendChild(input);
            // Select the text inside the input element
            input.select();
            // Execute the copy command
            document.execCommand('copy');
            // Remove the input element from the DOM
            document.body.removeChild(input);
            setCopySuccess('Copied!');
            toast.success("Copied!",{position: toast.POSITION.TOP_CENTER,autoClose:500});
      };
    const handelPayment = async (e) => {
        e.preventDefault();
        if(transactionNo.length < 6)
        {
            toast.error("လုပ်ငန်းစဉ်အမှတ်(၆)လုံးထည့်ပါ",{position: toast.POSITION.TOP_CENTER,autoClose:false});
            return false;
        }
        const depositArray = {
            "pay_type": props.pay,
            "unit": props.amount,
            "transaction_no": transactionNo,
            "phone": phone,
            "owner_user_id": helperid
          };
          setPhone("");
          setHelperId("");
          setTransactionNo("");
          if(!submitted){
            setSubmitted(true);
            try{
              toast.info("လုပ်ဆောင်နေသည်..ခဏစောင့်ပါ",{position: toast.POSITION.TOP_CENTER,autoClose:1000});
              const response = await fetch(endpointURL+"/api/v1/wallet-deposit",{
                  method:"POST",
                  headers: { 
                    'Content-Type': 'application/json',
                    'Authorization':  'Bearer '+localStorage.getItem('token'),
                  },
                  body: JSON.stringify(depositArray),
              });
              console.log(response);
              const result = await response.json();
              if(result.status === true)
              {
                toast.success(result.msg,{position: toast.POSITION.TOP_CENTER,autoClose:2000});
                setTimeout(()=>{
                  window.location.href="/";
                },2100)
              }else
              {
                toast.error(result.msg,{position: toast.POSITION.TOP_CENTER,autoClose:false});
              }
            }catch(error){
              toast.error("Error occurred!Try again",{position: toast.POSITION.TOP_CENTER,autoClose:false});
            }
            setSubmitted(false);
        }
    }
    const handleSecondPage = event => {
        props.setAmount("");
        props.setStepOne(false);
        props.setStepTwo(true);
        props.setStepThree(false);
    };
    const payAccount = async (event) => {
        try{
            const response = await fetch(endpointURL+"/api/v1/pay-account",{
                method:"POST",
                headers: { 
                'Content-Type': 'application/json',
                'Authorization':  'Bearer '+localStorage.getItem('token'),
                },
                body: JSON.stringify({pay_type:props.pay}),
            });
            if(!response.ok)
            {
                toast.error("Response error",{position: toast.POSITION.TOP_CENTER,autoClose:false});
            }
            const result = await response.json();
            if(result.status == true)
            {
                setPhone(result.data.phone);
                setHelperId(result.data.user_id);
            }else
            {
                toast.error(result.msg,{position: toast.POSITION.TOP_CENTER,autoClose:false});
            }
        }catch(error){
            toast.error("Unauthenticated ..",{position: toast.POSITION.TOP_CENTER,autoClose:false});
        }
    }
    useEffect(()=>{
        payAccount();
    },[]);
    return (
        <div>
            <Helmet>
                <title>ကုဒ် ၆ လုံးရိုက်ထည့်ပါ</title>
            </Helmet>
            <a href="javascript:void(0);" style={{color:"rgb(204,204,204)",cursor:"pointer",marginBottom:"10px"}} onClick={handleSecondPage}><i className="bx bx-arrow-back" style={{color:"rgb(204,204,204)"}}></i> အဆင့် ၂ သို့</a>
            <p style={{color:"rgb(204,204,204)",textAlign:"center",marginTop:"10px"}}>လုပ်ငန်းစဉ်အမှတ်၏နောက်ဆုံး(၆)လုံးထည့်သွင်းပါ</p>
            <p style={{color:"rgb(204,204,204)",textAlign:"center"}}>အဆင့် ၃ မှ ၃ </p>
     
            <ol style={{ padding:"3px",margin:"3px",width: "100%",color:"rgb(204,204,204)",fontSize:"14px" }}>
                <li style={{color:"rgb(204,204,204)"}}>
                    {props.pay} နံပါတ် {phone} ကိုကူးပါ။ 
                    <button style={{marginLeft:"30px"}} onClick={copyText} className="btn btn-sm btn-outline-primary">ကူးမည်</button>
                </li>
                <li style={{color:"rgb(204,204,204)"}}>{props.amount} ကျပ်အား လွှဲပါ </li>
                <li style={{color:"rgb(204,204,204)"}}>လုပ်ငန်းစဉ်အမှတ် နောက်ဆုံး(၆)လုံးအား အောက်တွင်ထည့်သွင်းပါ</li>
            </ol>
            <hr></hr>
            <form onSubmit={handelPayment}>
                <div className="row mb-3 mx-auto mt-4">
                    <div className="col-8" style={{ width: "100%" }}>
                        <input type="text" onChange={(e) => setTransactionNo(e.target.value)} maxLength={6} className="col-11 form-control" placeholder="လုပ်ငန်းစဉ်အမှတ်၏နောက်ဆုံး(၆)လုံးထည့်ပါ" style={{textAlign:"center",fontWeight:"bold",padding:"12px",color:"gold",background:"white",border:"none",borderBottom:"2px solid #ccc",outline:"none"}}></input>
                    </div>
                </div>
                <div className="row mx-auto mt-3">
                    <div className="col-12" style={{ width: "100%",textAlign:'center' }}>
                        <button type="submit" className="btn btn-warning"><i className="bx bx-right-arrow-alt text-primary"></i> ပေးပို့မည်</button>
                    </div>
                </div>
            </form>
        </div>
    );
}