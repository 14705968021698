import { Button, Stack } from "react-bootstrap";
import Layout2 from "../layouts/Layout2";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
const PaymentAccountType = () => {
    const endpointURL =process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_MODE : process.env.REACT_APP_PRO_MODE;
    const [data,setData] = useState([]);
    const [isLoading,setIsLoading] = useState(false);
    const navigate = useNavigate();
    const handleFetch = async () => {
        try {
            const response = await fetch(endpointURL+'/api/v1/payment-account',{
              method:"POST",
              headers: { 
                  'Content-Type': 'application/json',
                  'Authorization':  'Bearer '+localStorage.getItem('token'),
                },
              body:[]
            });
            const result = await response.json(); 
            if(result.status === true)
            {
              setData(result.data);
              setIsLoading(false);
            }
          } catch (error) {
            console.error('fetch data error:', error);
          }
    }
    const accountInfo = (pay) => {
        navigate("/wallet/payment-info",{state:{pay_type:pay}});
    }
    useEffect(()=>{
        if(localStorage.getItem('token') == null || localStorage.getItem('token')=="")
        {
            navigate("/login");
        }else
        {
            setIsLoading(true);
            handleFetch();
        }
    },[]);
    return ( 
        <Layout2 pageType={"ငွေလက်ခံအကောင့်များ"}>
            <Helmet>
                <title>ငွေလက်ခံအကောင့်များ</title>
            </Helmet>
             <p style={{color:"rgb(204,204,204)",textAlign:"center"}}>ငွေထုတ်မည့်အမျိုးအစားရွေးပါ</p>
            <p style={{color:"rgb(204,204,204)",textAlign:"center"}}>အဆင့် ၂ မှ ၁</p>
            <Stack gap={4} className="mt-2 mx-auto">
                {
                    data.filter(e => e.pay_type === "kpay").length > 0 ?"":
                        <Button variant="outline-secondary  p-3" onClick={(e)=>accountInfo("KPAY")} >  
                                <img src="../kpay.png" style={{width:"40px",marginRight:"10px"}} alt="Logo"></img>
                                <Link> KBZ Pay ဖြင့်ငွေဖြည့်မည် </Link>
                        </Button>
                    
                }
                {
                    data.filter(e => e.pay_type === "wave").length > 0 ?"":
                        <Button variant="outline-secondary p-3" onClick={(e)=>accountInfo("WAVE")}>  
                            <img src="../wave.png" style={{width:"40px",marginRight:"10px"}} alt="Logo"></img> 
                            <Link>Wave Pay ဖြင့်ငွေဖြည့်မည်</Link> 
                        </Button>
                }
                
            </Stack>
        </Layout2>
     );
}
 
export default PaymentAccountType;