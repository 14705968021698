import React from "react";
import Header2 from "./Header2";
import {ToastContainer} from 'react-toastify';
import { isMobile,isDesktop } from 'react-device-detect';
const Layout2 = ({children,pageType}) => {
    return (  
        <div className="content">
            <Header2  type={pageType} ></Header2>
            <main className="page-body2" style={{marginTop:isMobile?"30px":"",minHeight:isDesktop?"100vh":"95vh",maxHeight:isDesktop?"100vh":"95vh"}}>
                {children}
            </main>
            <ToastContainer theme='dark' style={{marginTop:"35px"}}></ToastContainer>
        </div>
    );
}
 
export default Layout2;