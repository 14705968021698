import { useEffect, useState } from "react";
import Layout2 from "../layouts/Layout2";
import Form from 'react-bootstrap/Form'; 
import { toast } from "react-toastify";
import { Spinner, Stack } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
const WalletHistory = () => {
    const endpointURL =process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_MODE : process.env.REACT_APP_PRO_MODE;
    const [from,setFrom] = useState("");
    const [to,setTo]  = useState("");
    const [transactiontype,setTransactionType] = useState(1);
    const [deposit,setDeposit] = useState(true);
    const [withdrawl,setWithdrawl] = useState(false);
    const [data,setData] = useState([]);
    const [isLoading,setIsLoading] = useState(false);
    const navigate = useNavigate();
    const handleWithDrawlButton = (id) => {
        setTransactionType(id);
        setWithdrawl(true);
        setDeposit(false);
        handleWalletHistory(id);
    }
    const handleDepositButton = (id) => {
        setTransactionType(id);
        setWithdrawl(false);
        setDeposit(true);
        handleWalletHistory(id);
    }
    const fetchData = async (type) => {
        setIsLoading(true);
        const walletArray = {
            "transaction_type" : type,
            "from" : from,
            "to":to
        };
        try{
            const response = await fetch(endpointURL+"/api/v1/wallet-history",{
                method:"POST",
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization':  'Bearer '+localStorage.getItem('token'),
                },
                body: JSON.stringify(walletArray),
            });
            if(!response.ok)
            {
                toast.error("Response error",{position: toast.POSITION.TOP_CENTER,autoClose:false});
            }
            const result = await response.json();
            if(result.status == true)
            {
                setFrom(result.date.from);
                setTo(result.date.to);
                setData(result.data);
            }else
            {
                toast.error(result.msg,{position: toast.POSITION.TOP_CENTER,autoClose:false});
            }
        }catch(error){
            toast.error("Error occurred!Try again",{position: toast.POSITION.TOP_CENTER,autoClose:false});
        }
        setIsLoading(false);
    }
    const handleWalletHistory = async (type) => {
        fetchData(type);
    }
    const handleSearch = (e) => {
        e.preventDefault();
        fetchData(transactiontype);
    }
    useEffect(()=>{
        if(localStorage.getItem('token') == null || localStorage.getItem('token')=="")
        {
            alert('Login ဝင်ရောက်ပါ...');
            navigate("/");
        }else
        {
            handleWalletHistory(transactiontype);
        }
    },[]);
    return ( 
        <Layout2 pageType={"ငွေဖြည့်/ထုတ် မှတ်တမ်း"}>
            <Helmet>
                <title>ငွေဖြည့်/ထုတ် မှတ်တမ်း</title>
            </Helmet>
            <div className="row mb-3 mt-3">
                <div className="col-6">
                    <button onClick={(e)=> handleDepositButton(1) } className={`btn btn-md  ${deposit?'btn-success':'btn-outline-success'}  w-100`}>ငွေဖြည့်</button>
                </div>
                <div className="col-6">
                    <button onClick={(e)=> handleWithDrawlButton(2)} className={`btn btn-md  ${withdrawl?'btn-success':'btn-outline-success'} w-100`}>ငွေထုတ်</button>
                </div>
            </div>
            <Form className='row' onSubmit={ handleSearch}> 
                <Form.Group className='col-6'> 
                    <Form.Label>From:</Form.Label> 
                    <Form.Control type="date" defaultValue={from} onChange={(e)=> setFrom(e.target.value)} className='bg-dark' style={{color:"rgb(204,204,204)"}}/> 
                </Form.Group> 
                <Form.Group className='col-6'> 
                    <Form.Label>To:</Form.Label> 
                    <Form.Control type="date" defaultValue={to} onChange={(e)=> setTo(e.target.value)}  className='bg-dark' style={{color:"rgb(204,204,204)"}}/> 
                </Form.Group> 
                <Form.Group className='col-12 mt-2' style={{textAlign:'center'}}> 
                    <button className="btn btn-outline-primary"  type="submit"> 
                      <i className='lni lni-search text-primary'></i>  
                      ရှာမည်
                    </button> 
                </Form.Group> 
            </Form>
            <div>
                <Stack gap={1} className="mt-2 mx-auto">
                {
                    isLoading ? 
                        <div style={{textAlign:"center"}}>
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>  လုပ်ဆောင်နေသည်...ခဏစောင့်ပါ
                        </div>
                    :
                    data.length > 0 ?
                    data.map((list)=>(
                        <table className="table table-borderless" border="0" style={{background:"#0f0f0f",width:"100%",borderRadius:"20px",fontSize:"13px"}} >
                            <tbody>
                                <tr>
                                    <td rowSpan={2} style={{maxWidth:"30px",verticalAlign:"center"}}><img src={`../${list.pay_type}.png`} style={{width:"40px",marginTop:"3px"}} alt="Logo"></img></td>
                                    <td rowSpan={1} style={{textAlign:"center"}}>သို့:{list.phone}</td>
                                    <td rowSpan={1} style={{textAlign:"right"}}>{list.unit} ကျပ်</td>
                                </tr>
                                <tr>
                                    <td rowSpan={1} style={{textAlign:"center"}}>{list.date} {list.time} </td>
                                    <td rowSpan={1} style={{textAlign:"right",color:list.color}}>{list.status}</td>
                                </tr>
                            </tbody>
                        </table> 
                    ))
                    :
                    <div className="mx-auto p-2" style={{width:"100%",color:"rgb(204,204,204)",textAlign:"center"}}>
                        <p>မှတ်တမ်းမရှိပါ...</p>
                    </div>
                }
                </Stack>
            </div>
        </Layout2>
     );
}
 
export default WalletHistory;