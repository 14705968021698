import { useEffect, useState } from "react";
import Layout2 from "../layouts/Layout2";
import { Table,Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ThreeBetDetail from "../components/ThreeBetDetail";
import { Helmet } from "react-helmet";
const ThreeBet = () => {
    const endpointURL =process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_MODE : process.env.REACT_APP_PRO_MODE;
    const [isLoading,setIsLoading] = useState(false);
    const [bets,setBets] = useState([]);
    const [isLoadingDetail,setIsLoadingDetail] = useState(false);
    const [betdetailmodal,setBetDetailModal] = useState(false);
    const [betdetail,setBetDetail] = useState([]);
    const navigate = useNavigate();
    const handleFetch = async (e) =>{
        try{
            const response = await fetch(endpointURL+"/api/v1/three-bets",{
                method:"POST",
                headers: { 
                  'Content-Type': 'application/json',
                  'Authorization':  'Bearer '+localStorage.getItem('token'),
                },
            });
            const result = await response.json();
            if(response.status === 200)
            {
                if(result.status === true)
                {
                    setBets(result.data);
                }
            }else
            {
                toast.error(result.msg,{position: toast.POSITION.TOP_CENTER,autoClose:false});
            }
        }catch(error){
            toast.error("Error occurred!Try again",{position: toast.POSITION.TOP_CENTER,autoClose:false});
        }
        setIsLoading(false);
    }
    const handleBetDetail = async (date) => {
      /**
       * show modal true
       */
        setBetDetailModal(true);
        setIsLoadingDetail(true);
        try{
            let initialArray = {date:date};
            //console.log(initialArray);
            const response = await fetch(endpointURL+"/api/v1/three-bets-detail",{
                method:"POST",
                headers: { 
                  'Content-Type': 'application/json',
                  'Authorization':  'Bearer '+localStorage.getItem('token'),
                },
                body: JSON.stringify(initialArray),
            });
            const result = await response.json();
            //console.log(result);
            if(response.status === 200)
            {
              if(result.status === true)
              {
                setBetDetail(result.data);
                setIsLoadingDetail(false);
              }
            }else
            {
                toast.error(result.msg,{position: toast.POSITION.TOP_CENTER,autoClose:false});
            }
          }catch(error){
            toast.error("Error occurred!Try again",{position: toast.POSITION.TOP_CENTER,autoClose:false});
          }
    }
    useEffect(() =>{
        if(localStorage.getItem('token') === null || localStorage.getItem('token')==="")
        {
          alert('Login ဝင်ရောက်ပါ...');
          navigate("/");
        }else
        {
          setIsLoading(true);
          handleFetch();
          console.count('render counts:');
        }
      },[]);
    return ( 
        <Layout2 pageType={"ထိုးမှတ်တမ်း"}>
            <Helmet>
                <title>3D ထိုးမှတ်တမ်း</title>
            </Helmet>
            <p style={{color:"rgb(204,204,204)"}}>လွန်ခဲ့သော (၁)လအတွက် ဖော်ပြပေးထားပါသည်</p>    
            <Table striped bordered hover  style={{width:"100%",color:"rgb(204,204,204)"}}  variant="dark">
                <thead>
                    <tr>
                      <th style={{fontSize:"13px",padding:"10px"}}>ရက်စွဲ</th>
                      <th style={{fontSize:"13px"}}>ပမာဏ</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!isLoading ? 
                        bets.length > 0 ?
                          bets.map((bet,index) =>(
                              <tr key={index} onClick={() => handleBetDetail(bet.created_date)}>
                                  <td style={{cursor:"pointer"}}>{bet.created_date}</td>
                                  <td style={{cursor:"pointer"}}>{bet.amount}</td>
                              </tr>
                          )):
                          <tr><td colSpan={2} style={{textAlign:'center',color:"rgb(204,204,204)",fontSize:"13px",padding:"8px"}}>ထိုးမှတ်တမ်းမရှိသေးပါ...</td></tr>
                        :(
                            <tr>
                                <td colSpan={2} style={{textAlign:'center'}}>
                                  <Spinner animation="border" role="status">
                                      <span className="visually-hidden">Loading...</span>
                                  </Spinner>  လုပ်ဆောင်နေသည်...ခဏစောင့်ပါ
                                </td>
                            </tr>
                        )  
                    }
                  </tbody>
            </Table>
            <ThreeBetDetail 
                betlists={betdetail} 
                isLoadingDetail={isLoadingDetail} 
                show={betdetailmodal} 
                onHide={(e)=>setBetDetailModal(false)}>
            </ThreeBetDetail>
        </Layout2>
     );
}
 
export default ThreeBet;