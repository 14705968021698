import React from "react";
import { Link } from "react-router-dom";
const Footer = () => {
    return ( 
        <nav className="page-footer mobile-bottom-nav">
            <div className="mobile-bottom-nav__item" >
                <Link to={"/"} className="link"  >
                    <div  className="mobile-bottom-nav__item-content">
                        <i className="lni lni-home"></i>
                        မူလ
                    </div>
                </Link>	
            </div>
            <div className="mobile-bottom-nav__item">
                <Link to={"/wallet"} className="link">
                    <div className="mobile-bottom-nav__item-content default-nav">
                        <i className="lni lni-wallet"></i>
                        ပိုက်ဆံအိတ်
                    </div>
                </Link>		
            </div>
            <div className="mobile-bottom-nav__item">
                <Link to={"/promotions"} className="link">
                    <div className="mobile-bottom-nav__item-content default-nav">
                        <i className="lni lni-alarm"></i>
                        ပရိုမိုးရှင်း
                    </div>	
                </Link>	
            </div>
            
            <div className="mobile-bottom-nav__item">
                <Link to={"/profile"} className="link">
                    <div className="mobile-bottom-nav__item-content default-nav">
                        <i className="lni lni-user"></i>
                        ကျွန်ုပ်
                    </div>	
                </Link>	 
            </div>
        </nav> 
     );
}
 
export default Footer;