import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Table from 'react-bootstrap/Table';
import { toast } from "react-toastify";
const TwoBetList = (props) => {
    const [submitted,setSubmitted] = useState(false);
    const removeNumber = (number) => {
      props.setselected(props.selected.filter((digit) => digit.number !== number));
    }
    const handleAmount = (number,amount) => {
        const numberLists = props.selected.map(list => {
          if (list.number === number) {
            // No change
            return {...list,amount:amount}
          } 
          return list;
        });
        // Re-render with the new array
        props.setselected(numberLists);
    }
    const handleTwoBet = async (e) => {
      e.preventDefault();
      const betArray = {
        "time": props.time,
        "bets": props.selected
      };
      console.log(betArray);
      if(!submitted){
        setSubmitted(true);
        try{
          toast.info("လုပ်ဆောင်နေသည်..ခဏစောင့်ပါ",{position: toast.POSITION.TOP_CENTER,autoClose:1000});
          props.setBetModalShow(false);
          const endpointURL =process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_MODE : process.env.REACT_APP_PRO_MODE;
          const response = await fetch(endpointURL+"/api/v1/insert",{
              method:"POST",
              headers: { 
                'Content-Type': 'application/json',
                'Authorization':  'Bearer '+localStorage.getItem('token'),
              },
              body: JSON.stringify(betArray),
          });
          const result = await response.json();
          if(result.status === true)
          {
            props.setselected([]);
            toast.success(result.msg,{position: toast.POSITION.TOP_CENTER,autoClose:2000});
            // setTimeout(()=>{
            //   window.location.reload();
            // },2100)
          }else
          {
            toast.error(result.msg,{position: toast.POSITION.TOP_CENTER,autoClose:false});
          }
        }catch(error){
          toast.error("Error occurred!Try again",{position: toast.POSITION.TOP_CENTER,autoClose:false});
        }
        setSubmitted(false);
      }
    }
    const sum = props.selected.reduce((accumulator, currentValue) => {
      return parseInt(accumulator) + parseInt(currentValue.amount);
    }, 0);
    //console.log("The sum of betamount /"+sum);
    return ( 
        <Modal       
            {...props}
        >
        <Modal.Header style={{ background: "#0f0f0f", color: "#ccc", textAlign: "center" }}>
          <Modal.Title>
              ထိုးဂဏန်းများ
          </Modal.Title>
        </Modal.Header>
        <form onSubmit={handleTwoBet}>
          <Modal.Body style={{ background: "#1e2129", color: "white" }}>
                <Table striped bordered hover  style={{width:"100%"}}  variant="dark">
                  <thead>
                    <tr>
                      <th style={{width:"25%",maxWidth:"25%",fontSize:"13px",padding:"10px"}}>ထိုးဂဏန်း</th>
                      <th style={{width:"20%",maxWidth:"20%",fontSize:"13px"}}>အဆ</th>
                      <th style={{fontSize:"13px"}}>ထိုးငွေ</th>
                      <th style={{width:"20%",maxWidth:"20%",fontSize:"13px"}}>ဖျက်မည်</th>
                    </tr>
                  </thead>
                  <tbody>
                  {
                    props.selected.map((number) =>{
                      return (
                        <tr key={number.number}>
                          <td style={{padding:"10px",fontWeight:"bold",color:"#DDD",fontSize:"16px"}}>{number.number}</td>
                          <td style={{padding:"10px",fontWeight:"bold",color:"#DDD",fontSize:"16px"}}>85</td>
                          <td>
                            <input type="number" defaultValue={number.amount}  onChange={(e)=> handleAmount(number.number,e.target.value)}  style={{width:"90%",padding:"6px",background:"#0f0f0f",color:"#DDD",border:"none",outline:"none",fontSize:"16px"}}></input>
                          </td>
                          <td>
                            <button className="btn btn-sm" onClick={() => removeNumber(number.number) } style={{background:"red"}}>Delete</button>
                          </td>
                        </tr>
                      )
                    })
                  }
                  </tbody>
                </Table>
          </Modal.Body>
          <Modal.Footer style={{ background: "#0f0f0f", textAlign: "center" }}>
            <div style={{float:"left",textAlign:"left",marginRight:"20px"}}>Total <b>{sum}</b> MMK</div>
            <Button type="submit" variant="success"><i className="bx bx-save text-white"></i> ထိုးမည်</Button>
            <Button variant="danger" onClick={props.onHide}>
             <i className="lni lni-close text-white"></i> ပိတ်မည်
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
     );
}
 
export default TwoBetList;