import { Link } from "react-router-dom";
const WalletList = () => {
    let iconStyle ={
        backgroundColor:"black",
        cursor:"pointer",
        borderRadius:"50%",
        height:"50px",width:"50px",
        verticalAlign:"center",
        padding:"15px"
    };
    return ( 
            <div className="row" data-aos="fade-down">
                <hr></hr>
                <div className="col-4 text-center">
                    <Link to={"/wallet/deposit"} className="link">
                        <i className="bx bx-trophy rounded-circle" style={iconStyle}></i><br></br>
                        <span style={{fontSize:"13px"}}>ငွေဖြည့်</span>
                    </Link>
                </div>
                <div className="col-4 text-center">
                    <Link to="/wallet/payment-account" className="link">
                        <i className="bx bx-history rounded-circle" style={iconStyle}></i><br></br>
                        <span style={{fontSize:"13px"}} >ငွေထုတ်</span>
                    </Link>
                </div>
                <div className="col-4 text-center">
                    <Link to={"/wallet/history"} className="link">
                        <i className="bx bx-dollar rounded-circle" style={iconStyle}></i><br></br>
                        <span style={{fontSize:"13px"}} >မှတ်တမ်း</span>
                    </Link>
                </div>
                {/* <div className="col-12 mt-3 text-center">
                    <h6 style={{color:"rgb(204,204,204)"}}>ငွေဖြည့်/ငွေထုတ်အသုံးပြုနည်း</h6>
                </div>
                <div className="col-12 mt-3">
                    <i className="lni lni-youtube text-primary"></i>&nbsp;&nbsp;
                    <Link to="/guides/1" style={{fontSize:"14px"}}>ပိုက်ဆံအိတ်သို့ ငွေဖြည့်နည်း</Link><br/>
                </div>
                <div className="col-12 mt-2">
                    <i className="lni lni-youtube text-primary"></i>&nbsp;&nbsp;
                    <Link to="/guides/2" style={{fontSize:"14px"}}>ပိုက်ဆံအိတ်သို့ ငွေထုတ်နည်း</Link><br/>
                </div> */}
                <div className="col-12 mt-3 text-center">
                    <h6 style={{color:"rgb(204,204,204)"}}>ဟော့လိုင်းဖုန်း</h6>
                    <p style={{fontSize:"10px"}} className="text-primary">24x7 နာရီ ပိတ်ရက်မရှိဝန်ဆောင်မှုပေးနေပါသည်</p>
                </div>
                <div className="col-12  p-2">
                    <span style={{color:"rgb(204,204,204)"}}> 09 783288907</span>
                    <a href="tel:09783288907" className="btn btn-md btn-outline-success" style={{float:"right",width:"120px"}}>
                        <i className="lni lni-phone text-success"></i> Call
                    </a>
                </div>
                <div className="col-12  p-2">
                    <span style={{color:"rgb(204,204,204)"}}> +66824855639</span>
                    <a href="viber://chat?number=+66824855639" className="btn btn-md btn-outline-success" style={{float:"right",width:"120px"}}>
                        <i className="lni lni-phone text-success"></i> Viber
                    </a>
                </div>
            </div>
     );
}
export default WalletList;