import {  useParams } from "react-router-dom";
import Layout from "../layouts/Layout";
import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
const PromotionDetail = () => {
    const endpointURL =process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_MODE : process.env.REACT_APP_PRO_MODE;
    const [guides,setGuide] = useState([]);
    const [isLoading,setIsLoading] = useState(false);
    const [submitted,setSubmitted] = useState(false);
    const {id} = useParams();
    const handleFetch = async () => {
        try {
          const response = await fetch(endpointURL+'/api/v1/guides/'+id,{
            headers: { 
                'Content-Type': 'application/json'
              }
          });
          const result = await response.json(); 
          if(result.status === true)
          {
            setGuide(result.data);
            setIsLoading(false);
          }
        } catch (error) {
          console.error('fetch data error:', error);
        }
    };
    useEffect(()=>{
        if(!submitted){
            setIsLoading(true);
            setSubmitted(true);
            handleFetch();
            console.count('render count: ');
        }
        
    });
    //console.log(guides);

    return ( 
        <Layout>
            <Helmet>
                <title>Promotion Detail</title>
            </Helmet>
            {isLoading ? (
                     <div className="row text-center">
                        <div className="col-12">
                            <div className="p-4" style={{border:"1px solid #0d64ce",marginTop:"20px",borderRadius:"5px"}}>
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>  လုပ်ဆောင်နေသည်...ခဏစောင့်ပါ
                            </div>
                        </div>
                     </div>
                ):(
                <div>
                    <h5 className="mt-2" style={{color:"rgb(204,204,204)"}}>{guides.title}</h5>
                    <p dangerouslySetInnerHTML={{ __html: guides.content }} style={{color:"rgb(204,204,204)"}}></p>
                </div>
            )
            }
        </Layout>
     );
}
 
export default PromotionDetail;