import { useEffect, useState } from 'react';
import Layout2 from '../layouts/Layout2';
import Stack from 'react-bootstrap/Stack';
import { Button } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { Helmet } from 'react-helmet';
const TwoHistory = () => {
    const endpointURL =process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_MODE : process.env.REACT_APP_PRO_MODE;
    const [histories,setHistory] = useState([]);
    const [isLoading,setIsLoading] = useState(false);
    const getTwoHistory = async () => {
        try {
          const response = await fetch(endpointURL+'/api/v1/two-history',{
            headers: { 
                'Content-Type': 'application/json'
              }
          });
          const result = await response.json(); 
          if(result.status === true)
          {
            setHistory(result.data);
            setIsLoading(false);
          }
        } catch (error) {
          console.error('fetch data error:', error);
        }
    };
    useEffect(() => {
        setIsLoading(true);
        getTwoHistory();
        console.count("Render Count: ")
    },[]);
    return ( 
        <Layout2 pageType={"ထီပေါက်စဉ်မှတ်တမ်း"}>
            <Helmet>
                <title>2D History</title>
            </Helmet>
            <p style={{color:"rgb(204,204,204)",textAlign:"center"}}>လွန်ခဲ့သော (၁၀)ရက်အတွက် ဖော်ပြပေးထားပါသည်</p>    
                {isLoading ?(
                     <div className="row text-center">
                        <div className="col-12">
                            <div className="p-4" style={{border:"1px solid #0d64ce",marginTop:"20px",borderRadius:"5px"}}>
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>  လုပ်ဆောင်နေသည်...ခဏစောင့်ပါ
                            </div>
                        </div>
                     </div>
                    ): 
                    Object.keys(histories).map(key => (
                        Object.keys(histories[key]).map((key2) =>
                            <div className='mt-3'>
                                <p style={{textAlign:"center",width:"100%",fontSize:"20px"}}>{key2}</p>
                                { 
                                    histories[key][key2].map((list,index) => (
                                        <Stack gap={4} className="col-12 mt-2 mx-auto">
                                            <Button variant="outline-secondary">
                                               <table style={{width:"100%",color:"rgb(204,204,204)"}}>
                                                    <tr>
                                                        <td style={{color:"rgb(204,204,204)"}}>Set<br></br>{list.set}</td>
                                                        <td style={{color:"rgb(204,204,204)"}}>Value<br></br>{list.value}</td>
                                                        <td style={{color:"rgb(204,204,204)"}}>Number<br></br>{list.number}</td>
                                                        <td style={{color:"rgb(204,204,204)"}}>Time<br></br>{list.time}</td>
                                                    </tr>
                                               </table>
                                            </Button>
                                        </Stack>
                                    ))
                                }
                            </div>
                         )//s object
                    )) //f object 
                }
        </Layout2>
     );
}
 
export default TwoHistory;