import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './index.css';
import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';
import Wallet from './pages/Wallet';
import Promotion from './pages/Promotion';
import Profile from './pages/Profile';
import Login  from './pages/Login';
import Register from './pages/Register';
import ForgetPassword from './pages/ForgetPassword';
import TwoHome from './pages/TwoHome';
import TwoHomeCreate from './pages/TwoHomeCreate';
import TwoBet from './pages/TwoBet';
import TwoHistory from './pages/TwoHistory';
import CloseDate from './pages/CloseDate';
import Deposit from './pages/Deposit';
import WalletHistory from './pages/WalletHistory';
import OtpVerify from './pages/OtpVerify';
import Password from './pages/Password';
import OtpVerifyForget from './pages/OtpVerifyForget';
import ResetPassword from './pages/ResetPassword';
import AppDownload from './pages/AppDownload';
import ChangePassword from './pages/ChangePassword';
import ThreeHome from './pages/ThreeHome';
import ThreeHomeCreate from './pages/ThreeHomeCreate';
import ThreeBet from './pages/ThreeBet';
import ThreeHistory from './pages/ThreeHistory';
import PaymentAccount from './pages/PaymentAccount';
import PaymentAccountType from './pages/PaymentAccountType';
import PaymentInfo from './pages/PaymentInfo';
import WithdrawlPassword from './pages/WithdrawlPassword';
import TwoLuckyList from './pages/TwoLuckyList';
import ThreeLuckyList from './pages/ThreeLuckyList';
import Guide from './pages/Guide';
import PromotionDetail from './pages/PromotionDetail';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <BrowserRouter basename={process.env.REACT_APP_PUBLIC_URL}>
            <Routes>
                <Route path="/" element={<App />}></Route>
                <Route path="/wallet" element={<Wallet />}></Route>
                <Route path="/promotions" element={<Promotion />}></Route>
                <Route path="/profile" element={<Profile />}></Route>
                <Route path="/login" element={<Login />}></Route>
                <Route path="/register" element={<Register />}></Route>
                <Route path="/two-home" element={<TwoHome />}></Route>
                <Route path="/three-d/home" element={<Login />}></Route>
                <Route path="/two-home/create" element={<TwoHomeCreate />}></Route>
                <Route path="/two-home/two-bets" element={<TwoBet />}></Route>
                <Route path="/two-home/two-history" element={<TwoHistory />}></Route>
                <Route path="/two-home/close-date" element={<CloseDate></CloseDate>}></Route>
                <Route path="/wallet/deposit" element={<Deposit />}></Route>
                <Route path="/wallet/history" element={<WalletHistory></WalletHistory>}></Route>
                <Route path="/otp-verify" element={<OtpVerify></OtpVerify>}></Route>
                <Route path="/password" element={<Password></Password>}></Route>
                {/* <Route path="/forget-password" element={<ForgetPassword />}></Route> */}
                <Route path="/otp-verify-forget" element={<OtpVerifyForget></OtpVerifyForget>}></Route>
                <Route path='/reset-password' element={<ResetPassword></ResetPassword>}></Route>
                <Route path='/app-download'  element={<AppDownload></AppDownload>}></Route>
                <Route path='/change-password' element={<ChangePassword></ChangePassword>}></Route>
                <Route path='/three-home' element={<ThreeHome></ThreeHome>}></Route>
                <Route path='/three-home/create' element={<ThreeHomeCreate />}></Route>
                <Route path='/three-home/three-bets' element={<ThreeBet />}></Route>
                <Route path='/three-home/three-history' element={<ThreeHistory />}></Route>
                <Route path='/wallet/payment-account' element={<PaymentAccount />}></Route>
                <Route path='/wallet/payment-account-type' element={<PaymentAccountType />}></Route>
                <Route path='/wallet/payment-info' element={<PaymentInfo />}></Route>
                <Route path="/wallet/withdrawl-password" element={<WithdrawlPassword />}></Route>
                <Route path="/two-home/two-lucky-list" element={<TwoLuckyList></TwoLuckyList>}></Route>
                <Route path='/three-home/three-lucky-list' element={<ThreeLuckyList></ThreeLuckyList>}></Route>
                <Route path='/guides/:id' element={<Guide></Guide>}></Route>
                <Route path='/promotion-detail/:id' element={<PromotionDetail></PromotionDetail>}></Route>
            </Routes>
      </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

