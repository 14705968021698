import { useEffect, useState } from "react";
import Layout2 from "../layouts/Layout2";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
const Password = () => {
    const endpointURL =process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_MODE : process.env.REACT_APP_PRO_MODE;
    const location = useLocation();
    const username = location.state?.username;
    const name = location.state?.name;
    const [password,setPassword] = useState("");
    const [confirmpassword,setConfirmPassword] = useState("");
    const [isLoading,setIsLoading] = useState(false);
    const [submitted,setSubmitted] = useState(false);
    const [errorpassword,setErrorPassword] = useState("");
    const [errorconfirmpassword,setErrorConfirmPassword] = useState("");
    const [add_user_id,setAddUserId] = useState("");
    const [erroradduserid,setErrorAddUserId] = useState("");
    const navigate = useNavigate();
    const handleRegisterForm = async (e) => {
        e.preventDefault();
        const registerArray = {
            "password":password,
            "confirmpassword":confirmpassword,
            "name":name,
            "username":username,
            "add_user_id": add_user_id
        };
        console.log(add_user_id);
        setIsLoading(true);
        setErrorPassword("");
        setErrorConfirmPassword("");
        if(!submitted){
            setSubmitted(true);
            try{
                const response = await fetch(endpointURL+"/api/v1/create-account?apiKey=shwekyee",{
                    method:"POST",
                    headers:{
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(registerArray),
                });
                if(!response.ok)
                {
                    throw new Error('Request failed');
                }
                const resp = await response.json();
              //  console.log(resp);
                if(resp.status === true)
                {
                    toast.success(resp.msg,{position: toast.POSITION.TOP_CENTER,autoClose:1000});
                    localStorage.setItem('authenticated',true);
                    localStorage.setItem('token', resp.token);
                    navigate("/"); 
                }else{
                    setErrorAddUserId(resp.data.add_user_id);
                    setErrorPassword(resp.data.password); 
                    setErrorConfirmPassword(resp.data.confirmpassword); 
                }
            }catch(error){
                toast.error(error.message,{position: toast.POSITION.TOP_CENTER,autoClose:false});
                console.error('register failed: ',error.message);
            }
            setSubmitted(false);
        }
        setIsLoading(false);
    }
    useEffect(()=>{
        console.count('render count: ');
        if(location.state?.verify !== true)
        {
            navigate("/register");
        }
    });
    return ( 
        <Layout2 pageType={"စကားဝှက်ထည့်သွင်းပါ"}>
            <Helmet>
                <title>Password ထည့်ပါ</title>
            </Helmet>
            <p style={{color:"rgb(204,204,204)",textAlign:"center"}}>အကောင့်အတွက် စကားဝှက်ထည့်သွင်းပါ</p>  
            <form className="row" onSubmit={handleRegisterForm}>
                <div className="form-group">
                    <div className="col-12">
                        <label htmlFor="password">Password <b className="text-danger">*</b></label>
                        <input type="password" id="password" onChange={(e) => setPassword(e.target.value)} className="form-control bg-dark text-white mt-2" placeholder="Password" autoComplete="off"></input>
                        <label htmlFor="password" style={{color:"red"}}>{errorpassword}</label>
                    </div>
                </div>
                <div className="form-group">
                    <div className="col-12">
                        <label htmlFor="confirmpassword"> Confirm Password <b className="text-danger">*</b></label>
                        <input type="password" id="confirmpassword" onChange={(e) => setConfirmPassword(e.target.value)} className="form-control bg-dark text-white mt-2" placeholder="Confirm password" autoComplete="off"></input>
                        <label htmlFor="confirmpassword" style={{color:"red"}}>{errorconfirmpassword}</label>
                    </div>
                </div>
                <div className="form-group">
                    <div className="col-12">
                        <label htmlFor="add_user_id"> မိတ်ဆက်ကုဒ် <b className="text-danger">*</b></label>
                        <input type="number" id="add_user_id" onChange={(e) => setAddUserId(e.target.value)} className="form-control bg-dark text-white mt-2"  autoComplete="off"></input>
                        <label htmlFor="add_user_id" style={{color:"red"}}>{erroradduserid}</label>
                    </div>
                </div>
                <div className="form-group mt-4">
                    <div className="col-12" style={{textAlign:"center"}}>
                        <button type="submit" className="btn btn-md bg-warning"><i className="bx bx-right-arrow-alt text-white"></i> {isLoading?"Loading":"ရှေ့ဆက်မည်"}</button>
                    </div>
                </div>
            </form>  
        </Layout2>
     );
}
 
export default Password;