import React, { useEffect } from 'react';
import Layout from '../layouts/Layout';
import { Link, useNavigate } from 'react-router-dom';
import LoginMenu from '../components/LoginMenu';
import { Helmet } from 'react-helmet';
const Profile = () => {
    const navigate = useNavigate();
    const clearSession = (e) => {
        localStorage.removeItem("token");
        localStorage.removeItem('authenticated');
        navigate("/");
    };
    let myStyle ={
        color:"rgb(204,204,204)",
        borderBottom:"1px dashed #ccc",
        padding:"10px",
        textDecoration:"none"
    };
    useEffect(()=>{

    },[]);
    return ( 
        <Layout>
            <Helmet>
                <title>Profile</title>
            </Helmet>
            <LoginMenu></LoginMenu>
            {
                localStorage.getItem("authenticated") ? 
                <div className='row mt-2 p-3'>
                    {
                        localStorage.getItem("two_com") > 0 ?
                        <div className='col-12' style={myStyle}>
                            <i className='bx bx-dollar'></i>  2D ကော်မရှင် - {localStorage.getItem("two_com")} %
                        </div>:""
                    }
                    {
                        localStorage.getItem("three_com") > 0 ?
                        <div className='col-12' style={myStyle}>
                            <i className='bx bx-dollar'></i>  3D ကော်မရှင် - {localStorage.getItem("three_com")} %
                        </div>:""
                    }
                    {/* <Link to={"/change-password"} className='col-12' style={myStyle}>
                        <i className='bx bx-dollar'></i> ငွေရရှိမှုအခြေအနေ
                    </Link> */}
                    <Link to={"/change-password"} className='col-12' style={myStyle}>
                        <i className='bx bx-key'></i> PIN နံပါတ်ပြောင်းမည်
                    </Link>
                    <a onClick={clearSession} className='col-12' style={myStyle}>
                        <i className='bx bx-log-out'></i> စနစ်မှထွက်မည်
                    </a>
                </div>:""
            }
            <div style={{width:"100%",marginTop:"30px"}}>
                <center>Version 1.0.0</center>
            </div>
        </Layout>
     );
}
 
export default Profile;