import { useState } from "react";
import Layout2 from "../layouts/Layout2";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
const ChangePassword = () => {
    const endpointURL =process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_MODE : process.env.REACT_APP_PRO_MODE;
    const [oldpassword,setOldPassword] = useState("");
    const [password,setPassword] = useState("");
    const [confirmpassword,setConfirmPassword] = useState("");
    const [isLoading,setIsLoading] = useState(false);
    const [submitted,setSubmitted] = useState(false);
    const [erroroldpassword,setErrorOldPassword] = useState("");
    const [errorpassword,setErrorPassword] = useState("");
    const [errorconfirmpassword,setErrorConfirmPassword] = useState("");
    const handleChangePassword = async (e) => {
        e.preventDefault();
        const  passwordArray = {
            "oldpassword":oldpassword,
            "password":password,
            "confirmpassword":confirmpassword
        };
        setIsLoading(true);
        if(!submitted){
            setSubmitted(true);
            try{
                const response = await fetch(endpointURL+"/api/v1/change-password",{
                    method:"POST",
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization':  'Bearer '+localStorage.getItem('token'),
                    },
                    body: JSON.stringify(passwordArray),
                });
                if(!response.ok)
                {
                    throw new Error('Request failed');
                }
                const resp = await response.json();
                //console.log(resp);
                if(resp.status === true)
                {
                    toast.success(resp.msg,{position: toast.POSITION.TOP_CENTER,autoClose:1000});
                    window.location.reload();
                   // navigate("/"); 
                }else{
                    setErrorOldPassword(resp.data.oldpassword);
                    setErrorPassword(resp.data.password); 
                    setErrorConfirmPassword(resp.data.confirmpassword); 
                }
            }catch(error){
                toast.error(error.message,{position: toast.POSITION.TOP_CENTER,autoClose:false});
                console.error('change password failed: ',error.message);
            }
        }
        setSubmitted(false);
        setIsLoading(false);
    }
    return ( 
        <Layout2 pageType={"Password ပြောင်းမည်"}>
            <Helmet>
                <title>Password ပြောင်းလဲသတ်မှတ်ပါ</title>
            </Helmet>
            <p style={{color:"rgb(204,204,204)",textAlign:"center",fontWeight:900}}>ကျေးဇူးပြု၍ သင်၏ Password ကိုသတ်မှတ်ပါ</p>  
            <form className="row" onSubmit={handleChangePassword}>
                <div className="form-group">
                    <div className="col-12">
                        <label htmlFor="oldpassword">Password အဟောင်းကိုထည့်ပါ <b className="text-danger">*</b></label>
                        <input type="password" id="oldpassword" onChange={(e) => setOldPassword(e.target.value)} className="form-control bg-dark text-white mt-2" placeholder="Password" autoComplete="off"></input>
                        <label htmlFor="oldpassword" style={{color:"red"}}>{erroroldpassword}</label>
                    </div>
                </div>
                <div className="form-group">
                    <div className="col-12">
                        <label htmlFor="password">Password အသစ်ထည့်ပါ <b className="text-danger">*</b></label>
                        <input type="password" id="password" onChange={(e) => setPassword(e.target.value)} className="form-control bg-dark text-white mt-2" placeholder="Password" autoComplete="off"></input>
                        <label htmlFor="password" style={{color:"red"}}>{errorpassword}</label>
                    </div>
                </div>
                <div className="form-group">
                    <div className="col-12">
                        <label htmlFor="confirmpassword">Password အသစ်ကိုအတည်ပြုပါ <b className="text-danger">*</b></label>
                        <input type="password" id="confirmpassword" onChange={(e) => setConfirmPassword(e.target.value)} className="form-control bg-dark text-white mt-2 input-field" placeholder="Confirm password" autoComplete="off"></input>
                        <label htmlFor="confirmpassword" style={{color:"red"}}>{errorconfirmpassword}</label>
                    </div>
                </div>
                <div className="form-group mt-4">
                    <div className="col-12" style={{textAlign:"center"}}>
                        <button type="submit" className="btn btn-md bg-warning">
                            <i className="bx bx-right-arrow-alt text-white"></i>
                            {isLoading?"Loading":"ရှေ့ဆက်မည်"}
                        </button>
                    </div>
                </div>
            </form>  
        </Layout2>
    );
}
 
export default ChangePassword;