import React from 'react';
import Layout from './layouts/Layout';
import LoginMenu from './components/LoginMenu';
import { Link } from 'react-router-dom';
import KeenSlider from './components/KeenSlider';
import { Helmet } from 'react-helmet';

function App() {
  
  let id = "all";
  let imgSize= {
      width:"100%",
      height:"90px",
      boxShadow: "0 5px 10px #888888",
      borderRadius:"10px"
  };       
  return (
        <Layout>
           <Helmet>
                <title>Home Page</title>
          </Helmet>
          <LoginMenu></LoginMenu>
          <br></br>
          <KeenSlider id={id}></KeenSlider>
          <div className='row mt-4'>
              <div className='col-6' style={{textAlign:"center"}}>
                  <Link to={"/two-home"} className='link'>
                    <img src="https://admin.shwekyee.com/storage/announcements/17-02-2024_07_31_2023-2d_square-removebg-preview.png" style={imgSize} alt='lucky type'></img>
                  </Link>
                  <p>နှစ်လုံး</p>
              </div>
              <div className='col-6' style={{textAlign:"center"}}>
                  <Link to={"/three-home"} className='link'>
                    <img src="https://admin.shwekyee.com/storage/announcements/17-02-2024_07_31_2023-3D_Square-removebg-preview.png" style={imgSize} alt='lucky type'></img>
                  </Link>
                  <p>သုံးလုံး</p>
              </div>              
          </div> 
        </Layout>
  );
}

export default App;
