import { useEffect, useState } from "react";
import KeenSlider from "../components/KeenSlider";
import ThreeFeatureList from "../components/ThreeFeatureList";
import Layout2 from "../layouts/Layout2";
import { Button, Spinner, Stack } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
const ThreeHome = () => {
    const endpointURL =process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_MODE : process.env.REACT_APP_PRO_MODE;
    const [data,setData] = useState([]);
    const [isLoading,setIsLoading] = useState(false);
    const fetchData = async () => {
        setIsLoading(true);
        try {
          const response = await fetch(endpointURL+'/api/v1/three-history',{
            headers: { 
                'Content-Type': 'application/json'
              }
          });
          const result = await response.json(); 
          if(result.status === true)
          {
            setData(result.data);
            setIsLoading(false);
          }
        } catch (error) {
          console.error('fetch data error:', error);
        }
    };
    useEffect(() =>{
            console.count("render count: ");
            fetchData();
    },[]);
    return ( 
        <Layout2 pageType={"သုံးလုံးမှကြိုဆိုပါရစေ"}>
            <Helmet>
                <title>3D Home Page</title>
            </Helmet>
            <ThreeFeatureList></ThreeFeatureList>
            <KeenSlider id={3}></KeenSlider>
            {isLoading ?(
                     <div className="row text-center">
                        <div className="col-12">
                            <div className="p-4" style={{border:"1px solid #0d64ce",marginTop:"20px",borderRadius:"5px"}}>
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>  လုပ်ဆောင်နေသည်...ခဏစောင့်ပါ
                            </div>
                        </div>
                     </div>
                    ):
                    <Stack gap={2} className="mt-2 mx-auto">
                        {data.map((list)=>(
                                <Button variant="outline-secondary  p-3" key={list.id} >  
                                    <span className="three-date">{list.date}</span>
                                    <span className="three-number">{list.number ==="" ? "???":list.number}</span>
                                </Button>
                            ))
                        }
                    </Stack>
            }
            <div className="row mt-2">
                <div className="col-12  text-center">
                    <Link to="/three-home/create">
                        <button className="btn btn-outline-primary"><i className="bx bx-dollar text-primary"></i> ထိုးမည်</button>
                    </Link>
                </div>
            </div>
        </Layout2>
     );
}
 
export default ThreeHome;