import { useEffect, useState } from "react";
import Layout2 from "../layouts/Layout2";
import { Link, useNavigate } from "react-router-dom";
import { Button, Spinner } from "react-bootstrap";
import { Helmet } from "react-helmet";
const PaymentAccount = () => {
    const endpointURL =process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_MODE : process.env.REACT_APP_PRO_MODE;
    const [data,setData] = useState([]);
    const [isLoading,setIsLoading] = useState(false);
    const navigate = useNavigate();
    const handleFetch = async () => {
        try {
            const response = await fetch(endpointURL+'/api/v1/payment-account',{
              method:"POST",
              headers: { 
                  'Content-Type': 'application/json',
                  'Authorization':  'Bearer '+localStorage.getItem('token'),
                },
              body:[]
            });
            const result = await response.json(); 
            if(result.status === true)
            {
              setData(result.data);
              setIsLoading(false);
            }
          } catch (error) {
            console.error('fetch data error:', error);
          }
    }
    const toWithdrawlPassword = (pay_type) =>{
        navigate("/wallet/withdrawl-password",{state:{pay_type:pay_type}});
    }
    useEffect(()=>{
        if(localStorage.getItem('token') == null || localStorage.getItem('token')=="")
        {
            alert('Login ဝင်ရောက်ပါ...');
            navigate("/");
        }else
        {
            setIsLoading(true);
            handleFetch();
        }
    },[]);
    return ( 
        <Layout2 pageType={"ငွေလက်ခံအကောင့်များ"}>
            <Helmet>
                <title>ငွေလက်ခံအကောင့်များ</title>
            </Helmet>
                {isLoading?
                    <div style={{textAlign:"center"}}>
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>  လုပ်ဆောင်နေသည်...ခဏစောင့်ပါ
                    </div>
                    :
                        data.length > 0 ?
                            data.map((list,index)=>(
                                <div className="row mt-3" key={index}>
                                    <div className="col-12" onClick={()=>toWithdrawlPassword(list.pay_type)}>
                                        <Button s variant="outline-secondary" style={{textAlign:"left",padding:"5px",width:"100%"}}>
                                            <table style={{fontSize:"13px"}}>
                                                <tbody>
                                                    <tr>
                                                        <td rowSpan={2}><img src={`../${list.pay_type}.png`} style={{width:"30px"}} alt="Logo"></img></td>
                                                        <td rowSpan={1}>{list.name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td rowSpan={1}>{list.phone}</td>
                                                    </tr>
                                                </tbody>
                                            </table>  
                                        </Button>
                                    </div>
                                    {/* <div className="col-4" style={{textAlign:"right"}}> */}
                                        {/* <Button className="float-right" style={{width:"100%"}} variant="outline-success">
                                            ပြင်မည် 
                                        </Button> */}
                                    {/* </div> */}
                                </div>
                            ))
                        :
                        <div className="mx-auto p-3" style={{width:"100%",color:"rgb(204,204,204)",textAlign:"center"}}>
                            ငွေလက်ခံအကောင့်မရှိပါ...
                        </div>
                }
                {
                    isLoading?"":
                        data.length === 2 ?"":
                            <div className="text-center mt-3"> 
                                <Link to={"/wallet/payment-account-type"} className="btn btn-sm btn-warning">
                                    <i className="bx bx-plus text-white"></i>
                                    ငွေထုတ်အကောင့်အသစ်ထည့်မည်
                                </Link>
                            </div>
                }
            <div className="bg-secondary p-2 mt-4" style={{borderRadius:"5px"}}>
                <h5 className="text-center text-default"><i className="bx bx-info-circle"></i> ငွေထုတ်ရန်သတိပြုရမည့်အချက်များ</h5>
                <ul className="text-white">
                    <li style={{color:"#ccc"}}>အကောင့်ဖုန်းနံပါတ်အပြည့်အစုံထည့်ပါ</li>
                    {/* <li style={{color:"#ccc"}}>ငွေလက်ခံမည့် အကောင့်ကို(၁၅)ရက်တကြိမ် ပြောင်းလဲအသုံးပြုနိုင်ပါသည်</li> */}
                    <li style={{color:"#ccc"}}>ငွေထုတ်တင်ပြပြီး (၅)မိနစ်အတွင်း ငွေလက်ခံရရှိမည် ဖြစ်ပါသည်</li>
                </ul>
            </div>
        </Layout2>
     );
}
 
export default PaymentAccount;