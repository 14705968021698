import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Table from 'react-bootstrap/Table';
const TwoBetDetail = (props) => {
    return ( 
        <Modal       
            {...props}
            scrollable
        >
        <Modal.Header style={{ background: "#0f0f0f", color: "#ccc", textAlign: "center" }}>
            <Modal.Title>
                ထိုးခဲ့သည့်ဂဏန်းများ
            </Modal.Title>
        </Modal.Header>
          <Modal.Body style={{ background: "#1e2129", color: "white" }}>
                <Table striped bordered hover  style={{width:"100%",color:"rgb(204,204,204)"}}  variant="dark">
                  <thead>
                    <tr>
                      <th style={{width:"20%",maxWidth:"20%",fontSize:"13px"}}>အချိန်</th>
                      <th style={{fontSize:"13px"}}>ထိုးဂဏန်း</th>
                      <th style={{fontSize:"13px"}}>ထိုးငွေ</th>
                      <th style={{fontSize:"13px"}}>ထိုးချိန်</th>
                    </tr>
                  </thead>
                  <tbody>
                  { !props.isLoadingDetail?
                    props.betlists.map((list) => (
                        <tr>
                            <td>{list.time}</td>
                            <td>{list.number}</td>
                            <td>{list.unit}</td>
                            <td>{list.transaction_time}</td>
                        </tr>
                    ))
                    :(
                        <tr>
                            <td colSpan={4} style={{textAlign:"center"}}>လုပ်ဆောင်နေသည်...ခဏစောင့်ပါ</td>
                        </tr>
                    )
                  }
                  </tbody>
                </Table>
          </Modal.Body>
          <Modal.Footer style={{ background: "#0f0f0f", textAlign: "center" }}>
            <Button variant="danger" onClick={props.onHide}>
                <i className="lni lni-close text-white"></i> ပိတ်မည်
            </Button>
          </Modal.Footer>
      </Modal>
     );
}
 
export default TwoBetDetail;