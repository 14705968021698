import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
const ColorMean = (props) => {
    return ( 
        <Modal  {...props}>
        <Modal.Header style={{ background: "#0f0f0f", color: "#ccc", textAlign: "center" }}>
          <Modal.Title>
            အရောင် အဓိပ္ပါယ် 100% ဖြစ်သောအခါ ထီနံပါတ်များကို ပိတ်ပါမည်။
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ background: "#1e2129", color: "white" }}>
          <span style={{width: "30px",height: "30px",background: "green",borderRadius: "50%",display: "inline-block",}} ></span>
          <span
            style={{
              color: "white",
              fontWeight: "bold",
              verticalAlign: "middle",
            }}
          >
            {" "}
            50% အောက်{" "}
          </span>
          <br />
          <span
            style={{
              width: "30px",
              height: "30px",
              background: "yellow",
              borderRadius: "50%",
              display: "inline-block",
            }}
          ></span>
          <span
            style={{
              color: "white",
              fontWeight: "bold",
              verticalAlign: "middle",
            }}
          >
            {" "}
            50% မှ 90% အတွင်း{" "}
          </span>
          <br />
          <span
            style={{
              width: "30px",
              height: "30px",
              background: "red",
              borderRadius: "50%",
              display: "inline-block",
            }}
          ></span>
          <span
            style={{
              color: "white",
              fontWeight: "bold",
              verticalAlign: "middle",
            }}
          >
            {" "}
            90% အထက်{" "}
          </span>
          <br />
          <span
            style={{
              width: "30px",
              height: "30px",
              background: "aqua",
              borderRadius: "50%",
              display: "inline-block",
            }}
          ></span>
          <span
            style={{
              color: "white",
              fontWeight: "bold",
              verticalAlign: "middle",
            }}
          >
            {" "}
            အပြည့် (100%){" "}
          </span>
          <br />
        </Modal.Body>
        <Modal.Footer style={{ background: "#0f0f0f", textAlign: "center" }}>
          <Button variant="danger" onClick={props.onHide}>
            ပိတ်မည်
          </Button>
        </Modal.Footer>
      </Modal>
     );
}
 
export default ColorMean;