import React, { useEffect,  useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Layout2 from "../layouts/Layout2";
import TwoBetList from "../components/TwoBetList";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
const TwoHomeCreate = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [quickSelectShow, setQuickSelect] = useState(false);
  const handleQuickSelectClose = () => setQuickSelect(false);
  const handleQuickSelectShow  = () => setQuickSelect(true);
  const [selected, setselected] = useState([]);
  const [numbers,setNumbers] = useState([]);
  const [showbetmodal, setBetModalShow] = useState(false);
  const [betamount,setBetAmount] = useState(100);
  const [closeTime,setCloseTime] = useState("");
  const [breakTime,setBreakTime] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data,setData] = useState([]);
  const [time,setTime] = useState("");
  //const betAmountRef = useRef();
  const navigate = useNavigate();
  const endpointURL =process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_MODE : process.env.REACT_APP_PRO_MODE;
  const handleSameDigit = () => {
     let sameDigits = ["00","11","22","33","44","55","66","77","88","99"];
     sameDigits.forEach((digit,index) => {
      if(selected.filter(e => e.number === digit).length === 0) {
        selected.push({number:digit,amount: betamount})
      }
     });
     setQuickSelect(false);
  }
  const handleKyi = () => {
    let Kyi = ["50","51","52","53","54","55","56","57","58","59","60","61","62","63","64","65","66","67","68","69","70","71","72","73",
              "74","75","76","77","78","79","80","81","82","83","84","85","86","87","88","89","90","91","92","93","94","95","96","97","98","99"];
    Kyi.forEach((digit,index) => {
      if(selected.filter(e => e.number === digit).length === 0) {
        selected.push({number:digit,amount: betamount})
      }
    });
    setQuickSelect(false);
  }
  const handleNge = () => {
    let Nge = ["00","01","02","03","04","05","06","07","08","09","10","11","12","13","14","15","16","17","18","19","20","21","22","23",
              "24","25","26","27","28","29","30","31","32","33","34","35","36","37","38","39","40","41","42","43","44","45","46","47","48","49"];
    Nge.forEach((digit,index) => {
       if(selected.filter(e => e.number === digit).length === 0) {
        selected.push({number:digit,amount: betamount})
       }
      //  if (selected.includes(digit) === false) {
      //      selected.push(digit);
      //  }
    });
    setQuickSelect(false);
  }
  const handleMa = () => {
    let Ma = ["01","03","05","07","09","11","13","15","17","19","21","23","25","27","29","31","33","35","37","39","41","43","45","47","49"
            ,"51","53","55","57","59","61","63","65","67","69","71","73","75","77","79","81","83","85","87","89","91","93","95","97","99"];
    Ma.forEach((digit,index) => {
      if(selected.filter(e => e.number === digit).length === 0) {
        selected.push({number:digit,amount: betamount})
       }
    });
    setQuickSelect(false);
  }
  const handleSone = () => {
    let Sone = ["01","03","05","07","09","11","13","15","17","19","21","23","25","27","29","31","33","35","37","39","41","43","45","47","49"
            ,"51","53","55","57","59","61","63","65","67","69","71","73","75","77","79","81","83","85","87","89","91","93","95","97","99"];
      Sone.forEach((digit,index) => {
        if(selected.filter(e => e.number === digit).length === 0) {
          selected.push({number:digit,amount: betamount})
        }
    });
    setQuickSelect(false);
  }
  const handleSoneSone = () => {
    let soneSone =  ["00","02","04","06","08","20","22","24","26","28","40","42","44","46","48","60","62","64","66","68","80","82","84","86","88"];
    soneSone.forEach((digit,index) => {
      if(selected.filter(e => e.number === digit).length === 0) {
        selected.push({number:digit,amount: betamount})
      }
    });
    setQuickSelect(false);
 }
  const handleSoneMa = () => {
    let soneMa =  ["01","03","05","07","09","21","23","25","27","29","41","43","45","47","49","61","63","65","67","69","81","83","85","87","89"];
    soneMa.forEach((digit,index) => {
      if(selected.filter(e => e.number === digit).length === 0) {
        selected.push({number:digit,amount: betamount})
      }
    });
    setQuickSelect(false);
  }
  const handleMaMa = () => {
    let MaMa =  ["11","13","15","17","19","31","33","35","37","39","51","53","55","57","59","71","73","75","77","79","91","93","95","97","99"];
    MaMa.forEach((digit,index) => {
      if(selected.filter(e => e.number === digit).length === 0) {
        selected.push({number:digit,amount: betamount})
      }
    });
    setQuickSelect(false);
  }
  const handleMaSone = () => {
    let MaSone =  ["10","12","14","16","18","30","32","34","36","38","50","52","54","56","58","70","72","74","76","78","90","92","94","96","98"];
    MaSone.forEach((digit,index) => {
      if(selected.filter(e => e.number === digit).length === 0) {
        selected.push({number:digit,amount: betamount})
      }
    });
    setQuickSelect(false);
  }
  const handlePad = (digit) => {
    for(var i=0;i<=9;i++)
    {
      let digit01 = digit+""+i;
      let digit02 = i+""+digit;
      if(selected.filter(e => e.number === digit01).length === 0) {
        selected.push({number:digit01,amount: betamount})
      }
      if(selected.filter(e => e.number === digit02).length === 0) {
        selected.push({number:digit02,amount: betamount})
      }
    }
    setQuickSelect(false);
  }
  const handleFront = (digit) => {
    for(var i=0;i<=9;i++)
    {
      let digitFront = digit+""+i;
      if(selected.filter(e => e.number === digitFront).length === 0) {
        const hotNumber = numbers.find(element => element.number === digitFront && element.hot === 1);
        let rNumber = i+""+digit;
        if(hotNumber !== undefined && digitFront !== rNumber) //hot 1 or 11, 00 
        {
          toast.info(digitFront+" သည် R/ဆတူ ထိုးမှရမယ်",{position: toast.POSITION.TOP_CENTER,autoClose:500});
          if(selected.filter(e => e.number === rNumber).length === 0) {
            selected.push({number:digitFront,amount:betamount},{number:rNumber,amount:betamount});
          }else
          {
            selected.push({number:digitFront,amount:betamount});
          }
        }else
        {
          selected.push({number:digitFront,amount:betamount});
        }
      }else
      {
        selected.push({number:digitFront,amount: betamount})
      }
    }
    setQuickSelect(false);
  }
  const handleBack = (digit) => {
    for(var i=0;i<=9;i++)
    {
      let digitBack = i+""+digit;
      if(selected.filter(e => e.number === digitBack).length === 0) {
        selected.push({number:digitBack,amount: betamount})
      }
    }
    setQuickSelect(false);
  }
  const handleBreak = (digit) => {
    var breakNumbers = [
                  ["00","19","91","28","82","37","73","46","64","55"],
                  ["01","10","29","92","38","83","47","74","56","65"],
                  ["02","20","11","39","93","48","84","57","75","66"],
                  ["03","30","12","21","49","94","58","85","67","76"],
                  ["04","40","13","31","22","59","95","68","86","77"],
                  ["05","50","14","41","23","32","69","96","78","87"],
                  ["06","60","15","51","24","42","33","79","97","88"],
                  ["07","70","16","61","25","52","34","43","89","98"],
                  ["08","80","17","71","26","62","35","53","44","99"],
                  ["09","90","18","81","27","72","36","63","45","54"]
              ];
    let digitArray = breakNumbers[digit];
    digitArray.forEach((digit,index) => {
      if(selected.filter(e => e.number === digit).length === 0) {
        selected.push({number:digit,amount: betamount})
      }
    });
    setQuickSelect(false);
  }
  const handleNatKhat = () => {
    var nagkhatDigit =["07","18","24","35","69"];
    nagkhatDigit.forEach((digit,index) => {
      if(selected.filter(e => e.number === digit).length === 0) {
        selected.push({number:digit,amount: betamount})
      }
    });
    setQuickSelect(false);
  }
  const handlePower = () => {
    var powerDigit = ["05","16","27","38","49"];
    powerDigit.forEach((digit,index) => {
      if(selected.filter(e => e.number === digit).length === 0) {
        selected.push({number:digit,amount: betamount})
      }
    });
    setQuickSelect(false);
  }
  const handleNyiKo = () => {
    var nyikoDigit =["01","12","23","34","45","56","67","78","89","90"]; 
    nyikoDigit.forEach((digit,index) => {
      if(selected.filter(e => e.number === digit).length === 0) {
        selected.push({number:digit,amount: betamount})
      }
    });
    setQuickSelect(false);
  }
  const handleNyiKoR = () => {
    var nyikoDigitR =["10","21","32","43","54","65","76","87","98","09"]; 
    nyikoDigitR.forEach((digit,index) => {
      if(selected.filter(e => e.number === digit).length === 0) {
        selected.push({number:digit,amount: betamount})
      }
    });
    setQuickSelect(false);
  }
  const handelTwenty = (id) => {
    if(id === 1)
    {
      var twenty =["00","01","02","03","04","05","06","07","08","09","10","11","12","13","14","15","16","17","18","19"]; 
    }else if(id === 2)
    {
      var twenty =["20","21","22","23","24","25","26","27","28","29","30","31","32","33","34","35","36","37","38","39"]; 
    }else if(id === 3)
    {
      var twenty =["40","41","42","43","44","45","46","47","48","49","50","51","52","53","54","55","56","57","58","59"]; 
    }else if(id === 4)
    {
      var twenty =["60","61","62","63","64","65","66","67","68","69","70","71","72","73","74","75","76","77","78","79"]; 
    }else if(id === 5)
    {
      var twenty =["80","81","82","83","84","85","86","87","88","89","90","91","92","93","94","95","96","97","98","99"]; 
    }
    twenty.forEach((digit,index) => {
      if(selected.filter(e => e.number === digit).length === 0) {
        selected.push({number:digit,amount: betamount})
      }
    });
    setQuickSelect(false);
  }
  const handleR = () => {
     var digitRArray = [];
      selected.forEach((digit,index) => {
           let digitR = digit.number;
           let digitReverse = digitR[1]+""+digitR[0];
          if(selected.filter(e => e.number === digitReverse).length === 0) {
            digitRArray.push({number:digitReverse,amount: betamount});
          }
      });
      setselected([...selected,...digitRArray]);
  }
  const betListShow = () => {
    //console.log(betAmount);
    if(betamount > 0)
    {
      if(selected.length > 0)
      {
        setBetModalShow(true);
      }
    }
  }
  
  const loginData = async () => {
      try {
        const headers = { 'Authorization':  'Bearer '+localStorage.getItem('token') };
        const response = await fetch(endpointURL+'/api/v1/loginData',{headers});
        const result = await response.json();
        setData(result.data);
        setIsLoading(false);
      } catch (error) {
        console.error('fetch data error:', error);
      }
  };
  const fetchData = async () => {
    try {
      const headers = { 'Authorization':  'Bearer '+localStorage.getItem('token') };
      const response = await fetch(endpointURL+'/api/v1/two-numbers',{headers});
      const result = await response.json();
     // console.log(result.data);
      if(result.status == false)
      {
        setBreakTime(true);
      }else
      {
        setNumbers(result.data);
        setCloseTime(result.closeTime);
        setTime(result.time);
      }
    } catch (error) {
      console.error('fetch number error:', error);
    }
  }
  useEffect(() => {
    console.count("render count");
    if(localStorage.getItem('token') == null || localStorage.getItem('token')=="")
    {
      navigate("/login");
    }
    setIsLoading(true);
    loginData();
    fetchData();
  }, []);
  const handleNumber = (number) => {
    if(selected.filter(e => e.number === number).length === 0) {
      const hotNumber = numbers.find(element => element.number === number && element.hot === 1);
      let rNumber = number[1]+number[0];
      if(hotNumber !== undefined && number !== rNumber) //hot 1 or 11, 00 
      {
        toast.info(number+" သည် R ထိုးမှရမယ်",{position: toast.POSITION.TOP_CENTER,autoClose:500});
        if(selected.filter(e => e.number === rNumber).length === 0) {
          setselected([...selected, {number:number,amount:betamount},{number:rNumber,amount:betamount}]);
        }else
        {
          setselected([...selected, {number:number,amount:betamount}]);
        }
      }else
      {
        setselected([...selected, {number:number,amount:betamount}]);
      }
    } else {
      setselected(selected.filter((select) => select.number !== number));
    }
      
  };
  const handleColor = (percent) =>{
    if(percent <= 50)
    {
      return "green";
    }else if(percent <= 90)
    {
      return "yellow";
    }else if(percent >90 && percent <= 99)
    {
      return "red";
    }else{
      return "aqua";
    }
  }
  const changeBetAmount = (e) =>{
    const numberLists = selected.map(list => {
        return {...list,amount:e.target.value}
    });
    // Re-render with the new array
    setBetAmount(e.target.value);
    setselected(numberLists);
  }
  //console.log(selected);
  return (
    <Layout2 pageType={"နှစ်လုံး မှကြိုဆိုပါသည်"}>
      <Helmet>
          <title>2D ထိုးမည်</title>
      </Helmet>
      <div className="row mt-0">
        <div className="col-12 text-center">
          <button
            onClick={handleQuickSelectShow}
            className="btn btn-md btn-success rounded-pill"
            style={{ width: "95%" }}
          >
            အမြန်ရွေး
          </button>
        </div>
        <div className="col-12 mt-2 mb-2 text-center">
          <input
            type="number"
            defaultValue={betamount}
            onChange={ changeBetAmount}
            placeholder="အနည်းဆုံး ၁၀၀ ကျပ်"
            style={{
              width: "95%",
              border: "none",
              padding: "7px",
              background: "#0f0f0f",
              outline: "none",
              borderBottom: "2px solid white",
            }}
          ></input>
        </div>
      </div>
      <div className="row text-center">
        <div className="col-6" style={{ marginTop: "7px" }}>
          <i className="lni lni-wallet"></i>
          <span style={{ fontSize: "14px", paddingLeft: "7px" }}>
           {!isLoading ? data.unit +" ကျပ်" :"Loading..." } 
          </span>
        </div>
        <div className="col-6">
          <span style={{ fontSize: "13px" }}>ထီပိတ်ချိန်</span>
          <br />
          <span style={{ fontSize: "13px" }}>{closeTime}</span>
        </div>
        <div className="col-6 p-2">
          <button
            className="btn btn-md btn-info rounded-pill"
            style={{ width: "95%" }}
            onClick={() => handleR() }
          >
            R
          </button>
        </div>
        <div className="col-6 p-2">
          <button
            className="btn btn-md btn-warning rounded-pill"
            style={{ width: "95%" }}
            onClick={betListShow}
          >
            ထိုးမည်
          </button>
        </div>
        <div className="col-6 p-2">
          <a href="#" style={{ color: "#0070E0" }} onClick={handleShow}>
            အရောင်အဓိပ္ပါယ်{" "}
          </a>
        </div>
        <div className="col-6 p-2">
          <a href="/two-home/create">အားလုံးရှင်းမည်</a>
        </div>
      </div>
      <div className="row text-center">
        <div className="col-12" style={{ width: "100%" }}>
          {!isLoading ?
            numbers.map((number) => (
            <button
              key={number.number}
              onClick={() => number.percent == 100? "":handleNumber(number.number)}
              className="btn-number"
              style={{backgroundColor: selected.filter(e => e.number === number.number).length > 0 ? "#6c757d":"#0f0f0f",
                    width: "18%",
                    padding: "12px 10px 12px 10px",
                    margin: "3px",
                    fontWeight:"bold",
                    borderRadius: "7px",
                    borderTop: "3px solid "+number.percent == 100? "aqua":"#ddd",
                    borderLeft: "3px solid "+number.percent == 100? "aqua":"#ddd",
                    borderRight: "3px solid #ddd",
                    borderBottom:"5px solid "+handleColor(number.percent)
             }}
            >
              {number.number}
            </button>
          ))
          :(
            <div className="mt-4 p-4">လုပ်ဆောင်နေသည် ခဏစောင့်ပါ...</div>
          )
        }
        { breakTime ? 
          <div className="text-danger  p-4" style={{border:"1px solid #0d64ce",marginTop:"100px",borderRadius:"5px"}}>
              <i className="lni lni-timer text-danger"></i> Break Time ဖြစ်ပါသည်
          </div>:""
        }
          
        </div>
      </div>
      {/**
        bootstrap modal
        */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header
          closeButton
          style={{ background: "#0f0f0f", color: "#ccc", textAlign: "center" }}
        >
          <Modal.Title>
            အရောင် အဓိပ္ပါယ် 100% ဖြစ်သောအခါ ထီနံပါတ်များကို ပိတ်ပါမည်။
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ background: "#1e2129", color: "white" }}>
          <span
            style={{
              width: "30px",
              height: "30px",
              background: "green",
              borderRadius: "50%",
              display: "inline-block",
            }}
          ></span>
          <span
            style={{
              color: "white",
              fontWeight: "bold",
              verticalAlign: "middle",
            }}
          >
            {" "}
            50% အောက်{" "}
          </span>
          <br />
          <span
            style={{
              width: "30px",
              height: "30px",
              background: "yellow",
              borderRadius: "50%",
              display: "inline-block",
            }}
          ></span>
          <span
            style={{
              color: "white",
              fontWeight: "bold",
              verticalAlign: "middle",
            }}
          >
            {" "}
            50% မှ 90% အတွင်း{" "}
          </span>
          <br />
          <span
            style={{
              width: "30px",
              height: "30px",
              background: "red",
              borderRadius: "50%",
              display: "inline-block",
            }}
          ></span>
          <span
            style={{
              color: "white",
              fontWeight: "bold",
              verticalAlign: "middle",
            }}
          >
            {" "}
            90% အထက်{" "}
          </span>
          <br />
          <span
            style={{
              width: "30px",
              height: "30px",
              background: "aqua",
              borderRadius: "50%",
              display: "inline-block",
            }}
          ></span>
          <span
            style={{
              color: "white",
              fontWeight: "bold",
              verticalAlign: "middle",
            }}
          >
            {" "}
            အပြည့် (100%){" "}
          </span>
          <br />
        </Modal.Body>
        <Modal.Footer style={{ background: "#0f0f0f", textAlign: "center" }}>
          <Button variant="danger" onClick={handleClose}>
            ပိတ်မည်
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={quickSelectShow} scrollable onHide={handleQuickSelectClose}>
        <Modal.Header
          closeButton
          style={{ background: "#0f0f0f", color: "#ccc", textAlign: "center" }}
        >
          <Modal.Title>
            အမြန်ရွေး 
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ background: "#1e2129", color: "white" }}>
                <p>ကြီး ငယ် စုံ မ အပူး</p>
                <button type="button"  onClick={()=> handleKyi()} className="btn btn-outline-primary group1" style={{marginRight:"8px",marginBottom:"8px"}}>ကြီး</button>
                <button type="button"  onClick={()=> handleNge()} className="btn btn-outline-primary group1" style={{marginRight:"8px",marginBottom:"8px"}}>ငယ်</button>
                <button type="button"  onClick={()=> handleMa()}   className="btn btn-outline-primary group1" style={{marginRight:"8px",marginBottom:"8px"}}>မ</button>
                <button type="button"  onClick={()=> handleSone()} className="btn btn-outline-primary group1" style={{marginRight:"8px",marginBottom:"8px"}}>စုံ</button>
                <button type="button"  onClick={()=> handleSameDigit()} className="btn btn-outline-primary group1" style={{marginRight:"8px",marginBottom:"8px"}}>အပူး</button>
			          <button type="button"  onClick={()=> handleSoneSone()}  className="btn btn-outline-primary group1" style={{marginRight:"8px",marginBottom:"8px"}}>စုံစုံ</button>
                <button type="button"  onClick={()=> handleSoneMa()} className="btn btn-outline-primary group1" style={{marginRight:"8px",marginBottom:"8px"}}>စုံမ</button>
			          <button type="button"  onClick={()=> handleMaMa()}   className="btn btn-outline-primary group1" style={{marginRight:"8px",marginBottom:"8px"}}>မမ</button>
			          <button type="button"  onClick={()=> handleMaSone()} className="btn btn-outline-primary group1" style={{marginRight:"8px",marginBottom:"8px"}}>မစုံ</button>
                <p>ပါတ်</p>
                <button type="button" onClick={()=>handlePad(0)} className="btn btn-outline-primary group3" style={{marginRight:"8px",marginBottom:"8px"}}>0</button>
                <button type="button" onClick={()=>handlePad(1)} className="btn btn-outline-primary group3" style={{marginRight:"8px",marginBottom:"8px"}}>1</button>
                <button type="button" onClick={()=>handlePad(2)} className="btn btn-outline-primary group3" style={{marginRight:"8px",marginBottom:"8px"}}>2</button>
                <button type="button" onClick={()=>handlePad(3)} className="btn btn-outline-primary group3" style={{marginRight:"8px",marginBottom:"8px"}}>3</button>
                <button type="button" onClick={()=>handlePad(4)} className="btn btn-outline-primary group3" style={{marginRight:"8px",marginBottom:"8px"}}>4</button>
                <button type="button" onClick={()=>handlePad(5)} className="btn btn-outline-primary group3" style={{marginRight:"8px",marginBottom:"8px"}}>5</button>
                <button type="button" onClick={()=>handlePad(6)} className="btn btn-outline-primary group3" style={{marginRight:"8px",marginBottom:"8px"}}>6</button>
                <button type="button" onClick={()=>handlePad(7)} className="btn btn-outline-primary group3" style={{marginRight:"8px",marginBottom:"8px"}}>7</button>
                <button type="button" onClick={()=>handlePad(8)} className="btn btn-outline-primary group3" style={{marginRight:"8px",marginBottom:"8px"}}>8</button>
                <button type="button" onClick={()=>handlePad(9)} className="btn btn-outline-primary group3" style={{marginRight:"8px",marginBottom:"8px"}}>9</button>
                <p>ထိပ်</p>
                <button type="button" onClick={()=> handleFront(0)}  className="btn btn-outline-primary group2" style={{marginRight:"8px",marginBottom:"8px"}}>0</button>
                <button type="button" onClick={()=> handleFront(1)} className="btn btn-outline-primary group2" style={{marginRight:"8px",marginBottom:"8px"}}>1</button>
                <button type="button" onClick={()=> handleFront(2)} className="btn btn-outline-primary group2" style={{marginRight:"8px",marginBottom:"8px"}}>2</button>
                <button type="button" onClick={()=> handleFront(3)} className="btn btn-outline-primary group2" style={{marginRight:"8px",marginBottom:"8px"}}>3</button>
                <button type="button" onClick={()=> handleFront(4)} className="btn btn-outline-primary group2" style={{marginRight:"8px",marginBottom:"8px"}}>4</button>
                <button type="button" onClick={()=> handleFront(5)} className="btn btn-outline-primary group2" style={{marginRight:"8px",marginBottom:"8px"}}>5</button>
                <button type="button" onClick={()=> handleFront(6)} className="btn btn-outline-primary group2" style={{marginRight:"8px",marginBottom:"8px"}}>6</button>
                <button type="button" onClick={()=> handleFront(7)} className="btn btn-outline-primary group2" style={{marginRight:"8px",marginBottom:"8px"}}>7</button>
                <button type="button" onClick={()=> handleFront(8)} className="btn btn-outline-primary group2" style={{marginRight:"8px",marginBottom:"8px"}}>8</button>
                <button type="button" onClick={()=> handleFront(9)} className="btn btn-outline-primary group2" style={{marginRight:"8px",marginBottom:"8px"}}>9</button>
                <p>နောက်</p>
                <button type="button" onClick={()=> handleBack(0)}  className="btn btn-outline-primary group2" style={{marginRight:"8px",marginBottom:"8px"}}>0</button>
                <button type="button" onClick={()=> handleBack(1)}  className="btn btn-outline-primary group2" style={{marginRight:"8px",marginBottom:"8px"}}>1</button>
                <button type="button" onClick={()=> handleBack(2)}  className="btn btn-outline-primary group2" style={{marginRight:"8px",marginBottom:"8px"}}>2</button>
                <button type="button" onClick={()=> handleBack(3)}  className="btn btn-outline-primary group2" style={{marginRight:"8px",marginBottom:"8px"}}>3</button>
                <button type="button" onClick={()=> handleBack(4)}  className="btn btn-outline-primary group2" style={{marginRight:"8px",marginBottom:"8px"}}>4</button>
                <button type="button" onClick={()=> handleBack(5)}  className="btn btn-outline-primary group2" style={{marginRight:"8px",marginBottom:"8px"}}>5</button>
                <button type="button" onClick={()=> handleBack(6)}  className="btn btn-outline-primary group2" style={{marginRight:"8px",marginBottom:"8px"}}>6</button>
                <button type="button" onClick={()=> handleBack(7)}  className="btn btn-outline-primary group2" style={{marginRight:"8px",marginBottom:"8px"}}>7</button>
                <button type="button" onClick={()=> handleBack(8)}  className="btn btn-outline-primary group2" style={{marginRight:"8px",marginBottom:"8px"}}>8</button>
                <button type="button" onClick={()=> handleBack(9)}  className="btn btn-outline-primary group2" style={{marginRight:"8px",marginBottom:"8px"}}>9</button>
                <p>ဘရိတ်</p>
                <button type="button" onClick={()=> handleBreak(0)}  className="btn btn-outline-primary group4" style={{marginRight:"8px",marginBottom:"8px"}}>0</button>
                <button type="button" onClick={()=> handleBreak(1)}  className="btn btn-outline-primary group4" style={{marginRight:"8px",marginBottom:"8px"}}>1</button>
                <button type="button" onClick={()=> handleBreak(2)}  className="btn btn-outline-primary group4" style={{marginRight:"8px",marginBottom:"8px"}}>2</button>
                <button type="button" onClick={()=> handleBreak(3)}  className="btn btn-outline-primary group4" style={{marginRight:"8px",marginBottom:"8px"}}>3</button>
                <button type="button" onClick={()=> handleBreak(4)}  className="btn btn-outline-primary group4" style={{marginRight:"8px",marginBottom:"8px"}}>4</button>
                <button type="button" onClick={()=> handleBreak(5)}  className="btn btn-outline-primary group4" style={{marginRight:"8px",marginBottom:"8px"}}>5</button>
                <button type="button" onClick={()=> handleBreak(6)}  className="btn btn-outline-primary group4" style={{marginRight:"8px",marginBottom:"8px"}}>6</button>
                <button type="button" onClick={()=> handleBreak(7)}  className="btn btn-outline-primary group4" style={{marginRight:"8px",marginBottom:"8px"}}>7</button>
                <button type="button" onClick={()=> handleBreak(8)}  className="btn btn-outline-primary group4" style={{marginRight:"8px",marginBottom:"8px"}}>8</button>
                <button type="button" onClick={()=> handleBreak(9)}  className="btn btn-outline-primary group4" style={{marginRight:"8px",marginBottom:"8px"}}>9</button>
                <p>နက္ခတ် ပါဝါ ညီကို ညီကိုR</p>
                <button type="button" onClick={()=> handleNatKhat()} className="btn btn-outline-primary group1" style={{marginRight:"8px",marginBottom:"8px"}}>နက္ခတ်</button>
                <button type="button" onClick={()=> handlePower()} className="btn btn-outline-primary group1" style={{marginRight:"8px",marginBottom:"8px"}}>ပါဝါ</button>
                <button type="button" onClick={()=> handleNyiKo()} className="btn btn-outline-primary group1" style={{marginRight:"8px",marginBottom:"8px"}}>ညီကို</button>
                <button type="button" onClick={()=> handleNyiKoR()}  className="btn btn-outline-primary group1" style={{marginRight:"8px",marginBottom:"8px"}}>ညီကိုR</button>
                <p>20 လုံးစီ</p>
                <button type="button" onClick={()=> handelTwenty(1)}   className="btn btn-outline-primary group" style={{marginRight:"8px",marginBottom:"8px"}}>00-19</button>
                <button type="button" onClick={()=> handelTwenty(2)}   className="btn btn-outline-primary group" style={{marginRight:"8px",marginBottom:"8px"}}>20-39</button>
                <button type="button" onClick={()=> handelTwenty(3)}   className="btn btn-outline-primary group" style={{marginRight:"8px",marginBottom:"8px"}}>40-59</button>
                <button type="button" onClick={()=> handelTwenty(4)}   className="btn btn-outline-primary group" style={{marginRight:"8px",marginBottom:"8px"}}>60-79</button>
                <button type="button" onClick={()=> handelTwenty(5)}   className="btn btn-outline-primary group" style={{marginRight:"8px",marginBottom:"8px"}}>80-99</button>
        </Modal.Body>
        <Modal.Footer style={{ background: "#0f0f0f", textAlign: "center" }}>
          <Button variant="danger" onClick={handleQuickSelectClose}>
            ပိတ်မည်
          </Button>
        </Modal.Footer>
      </Modal>
      <TwoBetList 
        selected={selected}
         time={time}
          setBetModalShow={setBetModalShow}
           setselected={setselected}
            betamount={betamount} 
            show={showbetmodal} 
            onHide={()=> setBetModalShow(false)}></TwoBetList>
    </Layout2>
  );
};

export default TwoHomeCreate;
