import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
const LoginMenu = (props) => {
    const [authenticated,setAuthenticated] = useState(localStorage.getItem('authenticated'));
    const [shouldFetch, setShouldFetch] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [data,setData] = useState([]);
    const endpointURL =process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_MODE : process.env.REACT_APP_PRO_MODE;
    const loginData = async () => {
        try {
          const headers = { 'Authorization':  'Bearer '+localStorage.getItem('token') };
          const response = await fetch(endpointURL+'/api/v1/loginData',{headers});
          const result = await response.json();
          setData(result.data);
          localStorage.setItem("two_com",result.data.two_commission);
          localStorage.setItem("three_com",result.data.three_commission);
          setIsLoading(false);
        } catch (error) {
          console.error('fetch data error:', error);
        }
    };
    useEffect(()=>{
        setIsLoading(true);
        if(authenticated)
        {
            setAuthenticated(true);
            if(shouldFetch)
            {
                loginData();
                setShouldFetch(false);
            }
        }
    },[shouldFetch,authenticated]);
    if(authenticated)
    {
        return ( 
            <div className="row" data-aos="fade-down">
                {!isLoading ? (
                    <Link to={"#"} className="link">
                        <div className="col-12 p-3 unauthorized-profile-menu">
                            <table style={{width:"100%"}}>
                                <tbody>
                                    <tr>
                                        <td rowSpan={2} style={{width:"40px"}}><i className="lni lni-user" style={{fontSize:"33px",marginRight:"10px"}}></i></td>
                                        <td>{data.name}</td>
                                        <td style={{width:"40%",textAlign:"right"}}>လက်ကျန်ငွေ</td>
                                    </tr>
                                    <tr>
                                        <td style={{}}>{data.username}</td>
                                        <td style={{width:"40%",textAlign:"right"}}>{data.unit}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </Link>
                ):(
                    <Link to={"#"} className="link">
                        <div className="col-12 p-3 unauthorized-profile-menu">
                            <table>
                                <tbody>
                                    <tr>
                                        <td><i className="lni lni-user" style={{fontSize:"33px",marginRight:"10px"}}></i></td>
                                        <td>Loading..</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </Link>
                )
                }
            </div>
         );
    }else
    {
        return ( 
            <div className="row" data-aos="fade-down">
                <Link to={"/login"} className="link">
                    <div className="col-12 p-3 unauthorized-profile-menu">
                        <i className="lni lni-user" style={{fontSize:"33px",marginRight:"10px"}}></i>
                        အကောင့်ဝင်မည်
                    </div>
                </Link>
            </div>
         );
    }
    
}
 
export default LoginMenu;