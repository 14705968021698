import React, { useEffect, useRef, useState } from "react"
import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"
const KeenSlider = (props) => {
  const endpointURL =process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_MODE : process.env.REACT_APP_PRO_MODE;
    const [isLoading,setIsLoading] = useState(false);
    const [shouldFetch, setShouldFetch] = useState(true);
    const sliderRef = useRef(null);
    const img = [
          "/storage/announcements/14-01-2024_07_08_2023-photo_2023-07-08 22.06.39.jpeg",
          "/storage/announcements/14-01-2024_11_25_2023-CCD8583B-43D3-4F59-B23B-D3D391BB0901.jpeg",
      ];
    const [image,setImage] = useState(img);
    const [sliderRefHandler] = useKeenSlider(
        {
          loop: true
        },
        [
          (slider) => {
            let timeout
            let mouseOver = false
            function clearNextTimeout() {
              clearTimeout(timeout)
            }
            function nextTimeout() {
              clearTimeout(timeout)
              if (mouseOver) return
              timeout = setTimeout(() => {
                console.log(slider);
                slider.next();
              }, 2000)
            }
            slider.on("created", () => {
              slider.container.addEventListener("mouseover", () => {
                mouseOver = true
                clearNextTimeout()
              })
              slider.container.addEventListener("mouseout", () => {
                mouseOver = false
                nextTimeout()
              })
              nextTimeout()
            })
            slider.on("dragStarted", clearNextTimeout)
            slider.on("animationEnded", nextTimeout)
            slider.on("updated", nextTimeout)
          },
        ]
    );
    const myStyle ={
        width:"100% !important",
        height:"140px",
        borderRadius:"15px",
    };
    const handleFetch = async () => {
      const myArray = {
        "type": props.id
      };
      try {
        const response = await fetch(endpointURL+'/api/v1/announcement',{
          method:'POST',
          headers: { 
              'Content-Type': 'application/json'
            },
          body: JSON.stringify(myArray)
        });
        const result = await response.json(); 
        if(result.status === true)
        {
          setImage(result.data);
          
        }
      } catch (error) {
        console.error('fetch data error:', error);
      }
  };
    useEffect(() => {
      setIsLoading(true);
      if(shouldFetch)
      {
        setShouldFetch(false);
        handleFetch();
        // Initialize the slider once the component has mounted
        if (sliderRef.current) {
          sliderRefHandler(sliderRef.current);
          setIsLoading(false);
        }
      }
     
    }, [sliderRefHandler]);
    return ( 
        <>
            { isLoading ? ""
                 :
                (
                    <div ref={sliderRef} className="keen-slider" style={{width:"100%"}}>
                      {  
                        image.length > 0 &&
                        image.map((list,indx)=>(
                          <img key={indx} className="keen-slider__slide slideshow-img" style={myStyle}  src={list.image}></img>
                        ))
                      }
                    </div>
                )
            }
        </>
     );
}
 
export default KeenSlider;