import React from 'react';
import Layout from '../layouts/Layout';
import LoginMenu from '../components/LoginMenu';
import WalletList from '../components/WalletList';
import { Helmet } from 'react-helmet';
const Wallet = () => {
    return ( 
        <Layout>
            <Helmet>
                <title>Wallet</title>
            </Helmet>
            <LoginMenu></LoginMenu> 
            <WalletList></WalletList>
        </Layout>
     );
}
 
export default Wallet;