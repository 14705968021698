import { Link } from "react-router-dom";
const FeatureList = () => {
    let iconStyle ={
        backgroundColor:"black",
        cursor:"pointer",
        borderRadius:"50%",
        height:"50px",width:"50px",
        verticalAlign:"center",
        padding:"15px"
    };
    return (  
        <div className="row mt-2">
            <div className="col-3 text-center">
                <Link to="/two-home/two-lucky-list" className="link" >
                    <i className="bx bx-trophy rounded-circle" style={iconStyle}></i><br></br>
                    <span style={{fontSize:"10px"}}>ထီပေါက်သူ</span>
                </Link>
            </div>
            <div className="col-3 text-center">
                <Link to="/two-home/two-history" className="link">
                    <i className="bx bx-history rounded-circle" style={iconStyle}></i><br></br>
                    <span style={{fontSize:"10px"}}>မှတ်တမ်း</span>
                </Link>
            </div>
            <div className="col-3 text-center">
                <Link to="/two-home/two-bets" className="link">
                    <i className="bx bx-dollar rounded-circle" style={iconStyle}></i><br></br>
                    <span style={{fontSize:"10px"}}>ထိုးမှတ်တမ်း</span>
                </Link>
            </div>
            <div className="col-3 text-center">
                <Link to="/two-home/close-date" className="link">
                    <i className="bx bx-task-x rounded-circle" style={iconStyle}></i><br></br>
                    <span style={{fontSize:"10px"}}>ပိတ်ရက်များ</span>
                </Link>
            </div>
        </div>
    );
}
 
export default FeatureList;
