import { Button, Spinner, Stack } from "react-bootstrap";
import Layout2 from "../layouts/Layout2";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
const ThreeHistory = () => {
    const endpointURL =process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_MODE : process.env.REACT_APP_PRO_MODE;
    const [histories,setHistory] = useState([]);
    const [isLoading,setIsLoading] = useState(false);
    const handleFetch = async () => {
        try {
          const response = await fetch(endpointURL+'/api/v1/three-history-last-ten',{
            headers: { 
                'Content-Type': 'application/json'
              }
          });
          const result = await response.json(); 
          if(result.status === true)
          {
            setHistory(result.data);
            setIsLoading(false);
          }
        } catch (error) {
          console.error('fetch data error:', error);
        }
    };
    useEffect(() => {
        setIsLoading(true);
        handleFetch();
        console.count("Render Count: ")
    },[]);
    return ( 
        <Layout2 pageType={"ထီပေါက်စဉ်မှတ်တမ်း"}>
                <Helmet>
                    <title>3D ပေါက်စဉ်မှတ်တမ်း</title>
                </Helmet>
                <p style={{color:"rgb(204,204,204)",textAlign:"center"}}>လွန်ခဲ့သော (၁၀)ကြိမ်အတွက် ဖော်ပြပေးထားပါသည်</p>    
                {isLoading ?(
                     <div className="row text-center">
                        <div className="col-12">
                            <div className="p-4" style={{border:"1px solid #0d64ce",marginTop:"20px",borderRadius:"5px"}}>
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>  လုပ်ဆောင်နေသည်...ခဏစောင့်ပါ
                            </div>
                        </div>
                     </div>
                    ): 
                    <Stack gap={2} className="mt-2 mx-auto" >
                        {histories.map((list)=>(
                                <Button variant="outline-secondary  p-3" key={list.id}>  
                                    <span className="three-date">{list.date}</span>
                                    <span className="three-number">{list.number ==="" ? "???":list.number}</span>
                                </Button>
                            ))
                        }
                    </Stack>
                }
        </Layout2>
     );
}
 
export default ThreeHistory;
