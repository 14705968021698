import { useEffect, useState } from "react";
import Layout2 from "../layouts/Layout2";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
const PaymentInfo = () => {
    const endpointURL =process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_MODE : process.env.REACT_APP_PRO_MODE;
    const location = useLocation();
    const [name,setName] = useState("");
    const [phone,setPhone] = useState("");
    const [confirmphone,setConfirmPhone] = useState("");
    const [submitted,setSubmitted] = useState(false);
    const [errorname,setErrorName] = useState("");
    const [errorphone,setErrorPhone] = useState("");
    const [errorconfirmphone,setErrorConfirmPhone] = useState("");
    const [isLoading,setIsLoading] = useState(false);
    const navigate = useNavigate();
    const handlePaymentInfo = async (e) => {
        e.preventDefault();
        const paymentInfoArray = {
            "name":name,
            "phone":phone,
            "confirmphone":confirmphone,
            "pay_type": location.state.pay_type
        };
        setIsLoading(true);
        setErrorName("");
        setErrorPhone("");
        setErrorConfirmPhone("");
        if(!submitted){
            setSubmitted(true);
            try{
                const response = await fetch(endpointURL+"/api/v1/payment-account-insert",{
                    method:"POST",
                    headers:{
                        'Content-Type': 'application/json',
                        'Authorization':  'Bearer '+localStorage.getItem('token'),
                    },
                    body: JSON.stringify(paymentInfoArray),
                });
                const resp = await response.json();
                if(response.status == 200)
                {
                    if(resp.status === true)
                    {
                        toast.success(resp.msg,{position: toast.POSITION.TOP_CENTER,autoClose:1000});
                        setTimeout(() => {
                            navigate("/wallet/payment-account"); 
                        }, 1100);
                    }
                }else{
                    setErrorName(resp.data.name); 
                    setErrorPhone(resp.data.phone); 
                    setErrorConfirmPhone(resp.data.confirmphone); 
                }
            }catch(error){
                toast.error(error.message,{position: toast.POSITION.TOP_CENTER,autoClose:false});
                console.error('register failed: ',error.message);
            }
            setSubmitted(false);
        }
        setIsLoading(false);
    }
    useEffect(()=>{
        if(localStorage.getItem('token') == null || localStorage.getItem('token')=="")
        {
            navigate("/login");
        }
    },[]);
    return ( 
        <Layout2 pageType={"ငွေလက်ခံအကောင့်"}>
            <Helmet>
                <title>ငွေလက်ခံအကောင့်များ</title>
            </Helmet>
            <p style={{color:"rgb(204,204,204)",textAlign:"center"}}>
                ငွေထုတ်ဖုန်းနံပါတ်ဖြစ်သည့်အတွက် အမှားအယွင်းမဖြစ်စေရန် တိကျစွာဖြည့်သွင်းပါ
            </p>
            <form className="row" onSubmit={handlePaymentInfo}>
                <div className="form-group">
                    <div className="col-12">
                        <label htmlFor="name">ငွေလက်ခံအကောင့် အမည် <b className="text-danger">*</b></label>
                        <input type="text" id="name" onChange={(e) => setName(e.target.value)} className="form-control bg-dark text-white mt-2" placeholder="အမည်ရိုက်ထည့်ပါ" autoComplete="off"></input>
                        <label htmlFor="name" style={{color:"red"}}>{errorname}</label>
                    </div>
                </div>
                <div className="form-group">
                    <div className="col-12">
                        <label htmlFor="phone"> ငွေလက်ခံဖုန်းနံပါတ်(09 ထည့်ရန်) <b className="text-danger">*</b></label>
                        <input type="number" id="phone" onChange={(e) => setPhone(e.target.value)} className="form-control bg-dark text-white mt-2" autoComplete="off"></input>
                        <label htmlFor="phone" style={{color:"red"}}>{errorphone}</label>
                    </div>
                </div>
                <div className="form-group">
                    <div className="col-12">
                        <label htmlFor="confirmphone"> နောက်တစ်ကြိမ်(09 ထည့်ရန်) <b className="text-danger">*</b></label>
                        <input type="number" id="confirmphone" onChange={(e) => setConfirmPhone(e.target.value)} className="form-control bg-dark text-white mt-2"  autoComplete="off"></input>
                        <label htmlFor="confirmphone" style={{color:"red"}}>{errorconfirmphone}</label>
                    </div>
                </div>
                <div className="form-group mt-4">
                    <div className="col-12" style={{textAlign:"center"}}>
                        <button type="submit" className="btn btn-md bg-warning">
                            <i className="bx bx-right-arrow-alt text-white"></i>
                            {isLoading?"Loading":"အတည်ပြု"}
                        </button>
                    </div>
                </div>
            </form>
        </Layout2>
     );
}
 
export default PaymentInfo;